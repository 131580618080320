<template>
  <div class="node-quiz-select-group">
    <template v-if="groups">
      <div
        v-for="(group, index) in groups"
        :key="index"
        class="select-group"
      >
        <div :class="[
          'radio',
          { selected: selection === index },
          { review },
          { incorrect: review && selection === index && index !== item.correct }  
        ]" @click.prevent="select(index)"></div>
        <swiper
          :ref="`swiper-${index}`"
          :direction="'vertical'"
          :free-mode="true"
          :loop="false"
          :mousewheel="true"
          :resistance="false"
          :scrollbar="{ draggable: true }"
          :slides-per-view="'auto'"
          :threshold="10"
          @touchStart="setParentState('lock-scroller')"
          @touchEnd="setParentState('unlock-scroller')"
          @mouseover="setParentState('lock-scroller')"
          @mouseout="setParentState('unlock-scroller')"
        >
          <swiper-slide v-for="(line, index) in group" :key="index">
            <div :class="[ 'group-row', item.classes ]">
              <p class="select-index mr-1" v-html="`${index + 1}.`"></p>
              <p v-html="line"></p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <NodeButton
              v-show="review || selection > -1"
              :item="item.submit"
              @action="onAction"
            />
          </swiper-slide>
        </swiper>
      </div>
    </template>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar]);

export default {
  name: 'NodeQuizSelectGroup',
  components: {
    NodeButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    review: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      swipers: [],
      groups: [],
      selection: -1,

      actionHandlers: {},
    }
  },
  methods: {
    initGroups() {
      this.groups = this.$getLocaleString(this.item.groups);
    },
    initSwipers() {
      this.groups.forEach((item, index) => {
        this.swipers.push(this.$refs[`swipe-${index}`].$el.swiper);
        this.swipers[index].update();
      });
    },
    select(answer) {
      this.selection = answer;
      this.submit();
    },
    submit() {
      const correct = this.selection === this.item.correct;
      this.$emit('action', { type: 'submit-answer', data: {
        question: this.item.question,
        answers: this.selection,
        correct,
        score: correct ? 1 : 0,
      } });
    },
    setParentState(type) {
      this.$emit('action', { type });
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
  },
  created() {
  },
  mounted() {
    if (this.review) {
      const progress = this.$store.getters.progress[this.progress];
      const selection = progress.answers[this.item.question];
      if (selection === undefined) {
        this.selection = -1;
      }
    }
    this.submit();
    this.initGroups();
  },
}
</script>
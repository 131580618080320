<template>
  <div :id="popup.id" class="node-popup node-popup-video">
    <div :class="[ 'popup-frame', popup.classes ]">

      <div v-if="video" class="video-content">
        <div class="video-container">
          <video
            ref="video"
            :key="videoIndex"
            preload="auto"
            :poster="video.poster"
          >
            <source :src="video.source" :type="video.type">
          </video>
          <div
            :class="[ 'video-overlay', { playing } ]"
            @mouseup.prevent="play"
            @touchend.prevent="play"
          ></div>
        </div>
      </div>
      <div v-else class="video-menu">
        <div
          v-for="(video, index) in popup.videos"
          :key="index"
          :class="[ 'video-item', { watched: watched.indexOf(index) > -1 } ]"
          @click="setVideo(index)"
        >
          <img :src="video.poster" alt="...">
        </div>
      </div>

      <div class="button-group button-group-bottom mb-10">
        <NodeButton
          v-if="video && popup.buttons['menu']"
          :item="popup.buttons['menu']"
          @action="onAction"
        />
        <NodeButton
          :item="popup.buttons['continue']"
          :inactive="watched.length < popup.required"
          @action="onAction"
        />
      </div>


      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

export default {
  name: 'NodePopupVideoMenu',
  components: {
    NodeButton,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      playing: false,
      video: null,
      watched: [],

      actionHandlers: {
        'menu': this.menu
      },
    }
  },
  methods: {
    registerVideo(index) {
      if (this.watched.indexOf(index) < 0) {
        this.watched.push(index);
      }
    },
    setVideo(index) {
      if (index < this.popup.videos.length) {
        this.videoIndex = index;
        this.video = this.popup.videos[index];
      }
    },
    play() {
      if (this.$refs['video'].paused) {
        this.$refs['video'].play();
        this.registerVideo(this.videoIndex || 0);
      } else {
        this.$refs['video'].pause();
      }
      this.playing = !this.$refs['video'].paused;

      if (this.popup.store) {
        this.storeWatched();
      }
    },
    pause() {
      if (!this.$refs['video'].paused) {
        this.$refs['video'].pause();
      }
      this.playing = !this.$refs['video'].paused;
    },
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    menu() {
      this.pause();
      this.videoIndex = -1;
      this.video = null;
    },
    
    storeWatched() {
      window.sessionStorage.setItem(`LimbLab_VIDEO_${this.popup.id}`, JSON.stringify(this.watched));
    },

    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
  },
  mounted() {
    if (this.popup.store) {
      const watched = window.sessionStorage.getItem(`LimbLab_VIDEO_${this.popup.id}`);
      this.watched = watched ? JSON.parse(watched) : [];
    }
    if (this.popup.videos.length === 1) {
      this.setVideo(0);
    }
  }
}
</script>

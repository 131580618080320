import Vue from 'vue'
import VueRouter from 'vue-router'

import LabView from '../views/LabView.vue'
import ScreenView from '../views/ScreenView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ScreenView,
    props: {
      screenIndex: 0,
    },
  },
  {
    path: '/reception',
    name: 'reception',
    component: ScreenView,
    props: {
      screenIndex: 1,
    },
  },
  {
    path: '/lab/zone-1',
    name: 'lab-zone-1',
    component: LabView,
    props: {
      screenIndex: 2,
    },
  },
  {
    path: '/lab/zone-2',
    name: 'lab-zone-2',
    component: LabView,
    props: {
      screenIndex: 3,
    },
  },
  {
    path: '/lab/zone-3',
    name: 'lab-zone-3',
    component: LabView,
    props: {
      screenIndex: 4,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  beforeEach (to) {
    if (!to.name) {
      return false;
    }
  }
})

export default router

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node-popup node-popup-quiz-alt",attrs:{"id":_vm.popup.id}},[_c('div',{class:[ 'popup-frame', _vm.popup.classes ]},[(_vm.items)?_c('div',{staticClass:"content"},[_c('p',{staticClass:"font-heavy text-size-36 letter-spacing-narrow mt-8 ml-16",domProps:{"innerHTML":_vm._s(_vm.$getLocaleString(_vm.popup.heading))}}),_c('div',{staticClass:"dropzone"},_vm._l((_vm.items),function(item){return _c('div',{key:item.index},[_c('div',{staticClass:"drop-label",domProps:{"innerHTML":_vm._s(item.label)}}),_c('div',{class:[
              'drop-region',
              { activated: _vm.selection[item.index] > -1 },
              { incorrect: _vm.review && _vm.modified.indexOf(item.index) < 0 && _vm.selection[item.index] > -1 && !_vm.hasCorrectAnswer(item) }
            ],domProps:{"innerHTML":_vm._s(_vm.selection[item.index] > -1 ? _vm.values[_vm.selection[item.index]].label : '')},on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDrop(item)}}})])}),0),_c('swiper',{ref:"swiper",attrs:{"direction":'vertical',"free-mode":true,"loop":false,"mousewheel":true,"resistance":false,"scrollbar":{ draggable: true },"slides-per-view":_vm.values.length > 18 ? 2 : 3,"threshold":10,"touch-start-prevent-default":false}},[_c('swiper-slide'),_c('swiper-slide',_vm._l((_vm.values),function(value,index){return _c('div',{key:index,class:[
              'draggable-value',
              { selected: _vm.dragValue === index },
              { activated: _vm.selection.indexOf(index) > -1 },
            ],attrs:{"draggable":_vm.selection.indexOf(index) < 0},domProps:{"innerHTML":_vm._s(value.label)},on:{"dragstart":function($event){return _vm.onDragStart($event, index)},"dragend":_vm.onDragEnd}})}),0),_c('swiper-slide')],1)],1):_vm._e(),_c('div',{staticClass:"button-group"},_vm._l((_vm.popup.items),function(item){return _c(item.type,{key:item.index,tag:"component",attrs:{"item":item},on:{"action":_vm.onAction}})}),1),_c('div',{staticClass:"close",on:{"mouseup":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)},"touchend":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }
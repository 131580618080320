<template>
  <div class="node-quiz-case-multiple mb-8">
    <div v-if="question && heading">
      <div
        v-for="(line, index) in heading"
        :key="index"
        :class="[ 'question-heading', line.classes ]"
        v-html="line.text"
      ></div>
      <ul class="list-style-none pl-0">
        <li
          v-for="(answer, index) in question.answers"
          :key="index"
          :id="`answer-${index}`"
          :class="[
            'relative mb-1 pl-8',
            { 'review mb-4': review },
            { selected: selection.indexOf(index) > -1 },
            (review && question.correct.indexOf(index) > -1) ? 'correct' : ''
          ]"
        >
          <div 
            :class="[
              'case-checkbox',
              { selected: selection.indexOf(index) > -1 },
            ]"
            @click.prevent="select(index)"
          ></div>
          <span class="answer-text fg-grey-dark text-size-24" :class="[ { 'fg-green': $store.getters.debug && question.correct.indexOf(index) > -1 } ]" v-html="answer"></span>
          <p
            v-if="review"
            class="rationale fg-grey-dark text-size-24"
            v-html="question.rationale[index]"
          ></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeQuizCaseMultiple',
  props: {
    question: {
      type: Object,
      required: true,
    },
    review: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selection: [],
      modified: false,
      count: 99,

      heading: null,
    }
  },
  methods: {
    select(answer) {
      this.modified = true;
      const index = this.selection.indexOf(answer);
      if (index > -1) {
        this.selection.splice(index, 1);
      } else if (this.selection.length < this.count) {
        this.selection.push(answer);
        this.selection = this.selection.sort();
      }
      this.submit();
    },
    submit() {
      if (this.review) return;
      this.$emit('action', { type: 'submit-answer', data: {
        question: this.question['question-index'],
        answers: this.selection,
      } });
    },
    isArray(item) {
      return Array.isArray(item);
    }
  },
  mounted() {
    this.count = this.question.count || 99;

    if (!this.review) {
      this.heading = this.question['heading'];
      this.submit();
    } else {
      this.selection = this.$store.getters.caseStudyQuestion(this.question['question-index']);
      this.heading = this.question['heading-rationale'];
    }
  },
}
</script>
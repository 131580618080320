<template>
  <div :id="popup.id" class="node-limb-lab">
    <h1 class="font-black-oblique text-size-33 text-right fg-white" v-html="$getLocaleString(popup.heading)"></h1>

    <div
      class="button-terminologies font-heavy text-size-15 fg-brand bg-white"
      v-html="$getLocaleString('GLOBAL_COMMON_TERMINOLOGIES')"
      @click="openGlobalPopup(popup.terminologies)"
    ></div>

    <div v-if="items" class="item-container">
      <img
        v-if="activeGroup > -1"
        :class="[ 'background', `opacity-${popup['background-opacity']}` ]"
        :src="`${popup.path}/background-${activeLocation}.png`"
        alt="..."
        draggable="false"
      >
      <img
        v-else
        class="background"
        :src="`${popup.path}/background.png`"
        alt="..."
        draggable="false"
      >

      <div
        v-for="(item, index) in items"
        v-show="activeLocation === item.location"
        :key="index"
        :class="[
          'item',
          { active: activeGroup === -1 || activeItems.indexOf(index) > -1 },
          { hidden: activeGroup === -1 && !popup['start-visible'] }
        ]"
        :style="getStyle(item.style)"
      >
        <img
          :src="`${popup.path}/${item.graphic}.png`"
          :alt="item.label.value"
          :draggable="false"
        >
        <img
          v-if="activeGroup > -1 && activeItems.indexOf(index) > -1"
          class="item-line"
          :style="getStyle(item.label.line)"
          :src="`${popup.path}/lines/${item.graphic}.svg`"
          alt="..."
        >
        <p
          v-if="activeGroup > -1 && activeItems.indexOf(index) > -1"
          :style="getStyle(item.label.style)"
          v-html="item.label.value"
        ></p>
      </div>
    </div>

    <div v-if="groups" :class="[ 'group-container', groups.classes ]">
      <div
        v-for="(group, index) in groups.items"
        :key="index"
        :class="[
          'button group-button font-medium',
          { active: activeGroup === index }
        ]"
        v-html="group.label"
        @click="setGroup(index)"
      ></div>
    </div>

    <div v-if="buttons" class="buttons">
      <template v-for="(button, index) in popup.buttons">
        <div
          v-if="activeLocation === index"
          :key="index"
          class="button bg-button-red"
          v-html="$getLocaleString(button)"
          @click="setGroup(button)"
        ></div>
      </template>
    </div>

    <NodeBack
      v-if="popup.back"
      :action="popup.back"
      @action="onAction"
    />
    <NodeNext
      v-if="popup.next && complete"
      :action="popup.next"
      :classes="complete ? 'flash' : ''"
      @action="onAction"
    />
    <div
      v-if="popup.final && complete"
      class="button button-final bg-button-blue-light"
      v-html="popup.final.label"
      @click="onAction(popup.final.action)"
    ></div>
    <div
      v-if="toggle"
      class="button-toggle flash"
      @click="toggleLocation()"
    ></div>

    <Transition>
      <NodePopup
        v-if="activePopup"
        :key="activePopup.index"
        :popup="activePopup"
        @action="onAction"
      >
        <img
          v-if="activePopup.graphic"
          class="info-image"
          :src="`${popup.path}/${activePopup.graphic}_info.png`"
        >
      </NodePopup>
    </Transition>
  </div>
</template>

<script>
import NodeBack from './NodeBack.vue'
import NodePopup from './NodePopup.vue'
import NodeNext from './NodeNext.vue'

export default {
  name: 'NodeLimbLabGrouping',
  components: {
    NodeBack,
    NodePopup,
    NodeNext,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeGroup: -1,
      activeLocationIndex: 0,
      activeLocation: 0,
      activeItems: [],
      activePopup: null,
      buttons: [],
      complete: false,
      groups: [],
      items: [],
      selection: [],
      toggle: false,

      actionHandlers: {
        'open-popup': this.openPopup,
        'open-global-popup': this.openGlobalPopup,
        'close-popup': this.closePopup,
      },
    }
  },
  methods: {
    initLimbLab() {
      this.groups = this.$getLocaleString(this.popup.groups);
      this.items = this.$getLocaleString(this.popup.items);
      
      this.groups.items.forEach(() => this.selection.push(false));
      this.initGroup();

      const limbLabData = this.$store.getters.limbLab[this.popup.stage];
      if (limbLabData && limbLabData.length > 0) {
        this.selection = limbLabData;
      } else {
        this.$store.commit('SetLimbLabStage', { stage: this.popup.stage, value: this.selection });
      }
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    openPopup(index) {
      if (this.popup.popups[index]) {
        this.activePopup = this.popup.popups[index];
      }
    },
    openGlobalPopup(key) {
      const popup = this.$store.getters.globalPopup(key);
      if (popup) {
        this.activePopup = popup;
      }
    },
    closePopup() {
      this.activePopup = null;
    },

    getStyle(rules) {
      let style = '';
      Object.keys(rules).forEach(key => {
        style += `${key}:${rules[key]};`;
      });
      return style;
    },
    
    initGroup() {
      this.activeGroup = -1;

      const group = this.groups.items[this.groups.start];
      this.activeLocationIndex = 0;
      this.activeLocation = this.groups.start;
      this.activeItems = group.items;
      this.toggle = false;
      this.complete = false;
    },
    setGroup(index) {
      this.activeGroup = index;

      const group = this.groups.items[Math.max(0, index)];
      this.activeItems = group.items;
      this.toggle = group.toggle;
      this.setLocation(0);

      if (index > -1) {
        this.selection[index] = true;
        this.complete = this.selection.every(item => item === true);
        this.$store.commit('SetLimbLabStage', { stage: this.popup.stage, value: this.selection });
      }
    },
    toggleLocation() {
      const index = this.activeLocationIndex === 0 ? 1 : 0;
      this.setLocation(index);
    },
    setLocation(index) {
      this.activeLocationIndex = index;
      this.activeLocation = this.groups.items[this.activeGroup].location[this.activeLocationIndex];
      console.log(this.activeLocationIndex, this.activeLocation);
    },

    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
  },
  mounted() {
    this.initLimbLab();
    
    if (this.popup.onopen) {
      const events = !Array.isArray(this.popup.onopen) ? [ this.popup.onopen ] : this.popup.onopen;
      events.forEach(event => this.onAction(event));
    }
  },
}
</script>

<template>
  <div :id="popup.id" class="node-popup node-popup-quiz node-popup-case-quiz">
    <div v-if="questions" :class="[ 'popup-frame', popup.classes ]">
      <h1 v-if="popup.heading" v-html="$getLocaleString(popup.heading)"></h1>

      <swiper
        ref="swiper"
        :direction="'vertical'"
        :free-mode="popup.freemode"
        :loop="false"
        :mousewheel="true"
        :resistance="false"
        :scrollbar="{ draggable: true }"
        :slides-per-view="popup['slides-per-view']"
        :threshold="10"
        :touch-start-prevent-default="false"
        :watch-overflow="true"
      >
        <swiper-slide
          :class="popup['slide-class']"
        >
          <NodeQuizCaseMultiple
            v-for="(question, questionIndex) in questions"
            :key="questionIndex"
            :question="question"
            :review="popup.review"
            @action="onAction"
          />
        </swiper-slide>
      </swiper>

      <div class="button-container">
        <NodeButton
          v-if="!popup.review"
          :item="popup.submit"
          :inactive="disableNext"
          @action="onAction"
        />
        <NodeButton
          v-else
          :item="popup.next"
          :inactive="disableNext"
          @action="onAction"
        />
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'
import NodeQuizCaseMultiple from './NodeQuizCaseMultiple.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupCaseQuiz',
  components: {
    NodeButton,
    NodeQuizCaseMultiple,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      questions: [],
      answers: [],
      swiper: null,
      disableNext: false,

      actionHandlers: {
        'submit': this.submit,
        'submit-answer': this.submitAnswer,
        'goto-slide': this.gotoSlide,
        'reset-swiper-event': this.resetSwiperEvent,
      },
    }
  },
  methods: {
    initQuestions() {
      if (this.popup.questions) {
        const questions = this.$getLocaleString(this.popup.questions);
        this.questions = this.popup.review ? [ questions[this.popup.question] ] : questions;
      }
    },
    getComponent(type) {
      return this.componentTypes[type];
    },
    close() {
      if (this.popup.onclose) {
        this.popup.onclose.forEach(event => {
          this.onAction(event);
        });
      } else {
        setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
      }
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    submit() {
      this.$store.commit('SetCaseStudy', this.answers);
    },
    submitAnswer(data) {
      this.answers[data.question] = data.answers;
    },
    resetSwiperEvent(event) {
      if (this.swiper) {
        this.swiper.onTouchEnd(event); // Clear swiper event
      }
    },
    initNext() {
      this.disableNext = !this.swiper.isEnd;
      if (this.disableNext) {
        this.swiper.on('reachEnd', () => {
          this.disableNext = false;
        });
      }
    },
  },
  created() {
    this.initQuestions();
    this.answers = this.$store.getters.caseStudy;
  },
  mounted() {
    this.swiper = this.$refs['swiper'].$el.swiper;
    
    setTimeout(() => {
      this.swiper.update();
      this.swiper.scrollbar.updateSize();
      this.initNext();
    }, 500);
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node-quiz-drop-table"},[_c('p',{staticClass:"question-text font-heavy text-size-40 fg-grey-dark mb-4",domProps:{"innerHTML":_vm._s(_vm.question.question)}}),(_vm.cells)?_c('div',{staticClass:"drop-table"},_vm._l((_vm.cells),function(row,index){return _c('div',{key:index,class:[ 'drop-table-row', { 'drop-table-header': index === 0 } ]},[_vm._l((row),function(cell,cellIndex){return [(cell.type === 'label')?_c('div',{key:cellIndex,staticClass:"drop-table-cell"},[_c('span',{domProps:{"innerHTML":_vm._s(cell.string)}})]):_c('div',{key:cellIndex,class:[ 'drop-table-blank', { wide: cellIndex === 2 } ]},[_c('div',{class:[
              'drop-region',
              cell.size,
              { activated: _vm.selection[cell.index] > -1 },
              { incorrect: _vm.review && _vm.modified.indexOf(cell.index) < 0 && _vm.selection[cell.index] > -1 && _vm.selection[cell.index] !== _vm.question.correct[cell.index] }
            ],domProps:{"innerHTML":_vm._s(_vm.selection[cell.index] > -1 ? _vm.values[_vm.selection[cell.index]].string : '')},on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDrop(cell)}}})])]})],2)}),0):_vm._e(),(_vm.values)?_c('div',{staticClass:"draggable-values"},_vm._l((_vm.values),function(value,index){return _c('div',{key:index,class:[
        'draggable-value',
        value.classes,
        value.size,
        { selected: _vm.dragValue === index },
        { activated: _vm.selection.indexOf(index) > -1 }
      ],attrs:{"draggable":_vm.selection.indexOf(index) < 0},domProps:{"innerHTML":_vm._s(value.string)},on:{"dragstart":function($event){return _vm.onDragStart($event, index)},"dragend":_vm.onDragEnd}})}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div 
    class="node-back" 
    @mouseup.prevent="back"
    @touchend.prevent="back"
  ></div>
</template>

<script>
export default {
  name: 'NodeBack',
  props: {
    action: {
      type: Object,
      required: false,
      // eslint-disable-next-line
      default: () => null,
    }
  },
  methods: {
    back() {
      this.$emit('action', this.action || { type: 'back' });
    },
  },
}
</script>

<template>
  <div :class="[ 'node-text pr-8', item.classes ]">
    <h3 class="font-nexa-heavy text-size-24 fg-blue mb-6" v-html="$getLocaleString(item.heading)"></h3>
    <ul class="list-style-none">
      <li
        v-for="(line, index) in lines"
        :key="index"
        class="flex font-nexa-light text-size-21 fg-grey-dark mb-4"
      >
        <span v-if="item.prefixed" v-html="`<strong>${index}</strong>:`"></span>
        <span class="ml-2" v-html="getLine(line)"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NodeGradingScale',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lines() {
      let lines = this.item.lines;
      if ('string' === typeof lines) {
        lines = this.$getLocaleString(lines);
      }
      return Array.isArray(lines) ? lines : [ lines ];
    },
  },
  methods: {
    getLineClasses(line) {
      return 'string' === typeof line
        ? ''
        : line.classes || '';
    },
    getLine(line) {
      const key = 'string' === typeof line ? line : line.string || '';
      return this.$getLocaleString(key);
    },
  },
}
</script>

<template>
  <div :id="popup.id" class="node-popup node-popup-result node-popup-confirm">
    <div :class="[ 'popup-frame', popup.classes ]">
      <h1 v-html="$getLocaleString('GLOBAL_KNOWLEDGE_CHECK')"></h1>

      <div class="content font-roman">
        <div class="icon icon-bulb"></div>

        <component
          v-for="item in popup.items"
          :key="item.index"
          :is="item.type"
          :item="item"
          @action="onAction"
        />

        <div class="button-container">
          <NodeButton
            v-if="popup.previous"
            :item="popup.previous"
            @action="onAction"
          />
          <NodeButton
            v-if="popup.next"
            :item="popup.next"
            @action="onAction"
          />
        </div>
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'
import NodeText from './NodeText.vue'

export default {
  name: 'NodePopupConfirm',
  components: {
    NodeButton,
    NodeText
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actionHandlers: {
        'close': this.close,
      },
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      if (this.popup.onclose) {
        this.popup.onclose.forEach(event => {
          this.onAction(event);
        });
      }
      this.$emit('action', { type: 'close-popup' });
    }
  },
}
</script>

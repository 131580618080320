<template>
  <div 
    v-show="item['activation-state'] === undefined || item['activation-state'].indexOf(state) > -1"
    :class="[ 'node-rollover', item.classes ]" 
    :style="getItemStyle(item.rect)"
    @click.prevent="processActions"
  >
    <div :class="[ 'rollover-wrapper', { enabled: state === item['require-state'] } ]">
      <img :src="item.source" alt="">
    </div>
    <div
      :class="[
        'icon',
        active ? item.icon.active : item.icon.type,
        { 'icon-lock' : state < item['require-state'] },
      ]"
      :style="getItemStyle(item.icon.rect)"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'NodeRollover',
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {}
  },
  methods: {
    getItemStyle(rect) {
      const properties = [ 'top', 'left', 'width', 'height', 'z-index' ];
      let style = '';
      properties.forEach(prop => {
        const units = prop === 'z-index' ? '' : 'rem';
        if (rect[prop] !== undefined) {
          style += `${prop}:${rect[prop]}${units};`
        }
      });
      return style;
    },

    processActions() {
      if (this.state < this.item['require-state']) {
        return;
      }

      this.item.actions.forEach(item => {
        this.$emit('action', item);
      });
    },
  },
}
</script>

<template>
  <div :id="popup.id" class="node-popup node-popup-quiz-alt">
    <div :class="[ 'popup-frame', popup.classes ]">
      <div v-if="items" class="content">
        <p
          class="font-heavy text-size-36 letter-spacing-narrow mt-8 ml-16"
          v-html="$getLocaleString(popup.heading)"
        ></p>
        
        <div class="dropzone">
          <div
            v-for="item in items"
            :key="item.index"
          >
            <div class="drop-label" v-html="item.label"></div>
            <div
              :class="[
                'drop-region',
                { activated: selection[item.index] > -1 },
                { incorrect: review && modified.indexOf(item.index) < 0 && selection[item.index] > -1 && !hasCorrectAnswer(item) }
              ]"
              @dragover.prevent
              @dragenter.prevent
              @drop.prevent="onDrop(item)"
              v-html="selection[item.index] > -1 ? values[selection[item.index]].label : ''"
            ></div>
          </div>
        </div>

        <swiper
          ref="swiper"
          :direction="'vertical'"
          :free-mode="true"
          :loop="false"
          :mousewheel="true"
          :resistance="false"
          :scrollbar="{ draggable: true }"
          :slides-per-view="values.length > 18 ? 2 : 3"
          :threshold="10"
          :touch-start-prevent-default="false"
        >
          <swiper-slide></swiper-slide>
          <swiper-slide>
            <div
              v-for="(value, index) in values"
              :key="index"
              :class="[
                'draggable-value',
                { selected: dragValue === index },
                { activated: selection.indexOf(index) > -1 },
              ]"
              :draggable="selection.indexOf(index) < 0"
              @dragstart="onDragStart($event, index)"
              @dragend="onDragEnd"
              v-html="value.label"
            ></div>
          </swiper-slide>
          <swiper-slide></swiper-slide>
        </swiper>
      </div>

      <div class="button-group">
        <component
          v-for="item in popup.items"
          :key="item.index"
          :is="item.type"
          :item="item"
          @action="onAction"
        />
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupQuizAlt',
  components: {
    NodeButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
    review: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      values: [],
      selection: [],
      swiper: null,
      modified: [],
      dragValue: -1,

      actionHandlers: {
        'submit': this.submit,
        'submit-answer': this.submitAnswer,
        'goto-slide': this.gotoSlide,
        'lock-scroller': this.lockScroller,
        'unlock-scroller': this.unlockScroller,
      },
    }
  },
  methods: {
    initQuestions() {
      if (this.popup.questions) {
        this.questions = this.$getLocaleString(this.popup.questions);
        
        const values = JSON.parse(JSON.stringify(this.questions.values));
        this.values = [];

        let index = 0;
        values.forEach(value => {
          for (let i = 0; i < value.count; i += 1) {
            this.values.push({ "label": value.label, "index": index ++ });
          }
        });

        const items = JSON.parse(JSON.stringify(this.questions.items));
        items.forEach(() => {
          this.selection.push(-1);
        });
        this.items = items;
      }
    },
    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },

    onDragStart(event, index) {
      this.swiper.onTouchEnd(event); // Clear swiper event
      event.dataTransfer.setData("text/plain", event.target.innerText);
      event.dataTransfer.setData("text/html", event.target.outerHTML);
      this.dragValue = index;
    },
    onDrop(item) {
      this.selection[item.index] = this.dragValue;
      if (this.modified.indexOf(item.index) < 0) {
        this.modified.push(item.index);
      }
      this.submit();
      this.dragValue = -1;
    },
    onDragEnd() {
      this.dragValue = -1;
    },

    hasCorrectAnswer(item) {
      const selectionIndex = this.selection[item.index];
      const value = selectionIndex > -1 ? this.values[selectionIndex].label : null;
      return item.correct === value;
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    submit() {
      const answers = [];

      this.score = 0;
      this.items.forEach(item => {
        const correct = this.hasCorrectAnswer(item);
        if (correct) {
          this.score += 1;
        }
        answers.push(this.selection[item.index]);
      });

      this.$store.commit('UpdateProgress', {
        question: this.popup.question,
        score: this.score,
        answers,
      });
    },
    submitAnswer(data) {
      this.selection[data.question] = data;
    },
    gotoSlide(data) {
      this.swiper.slideTo(data);
    },
    lockScroller() {
      this.swiper.detachEvents();
    },
    unlockScroller() {
      this.swiper.attachEvents();
    },
  },
  mounted() {
    this.initQuestions();
    
    if (this.review) {
      const progress = this.$store.getters.progress[this.popup.question];
      if (progress.answers !== undefined) {
        this.selection = progress.answers;
      }
    } else {
      this.submit();
    }

    setTimeout(() => {
      this.swiper = this.$refs['swiper'].$el.swiper;
      this.swiper.update();
    }, 500);
  },
}
</script>

<template>
  <div class="node-quiz-scale">
    <p v-if="!isArray(question.question)" class="question-text font-heavy text-size-40 fg-grey-dark" v-html="question.question"></p>
    <div v-else>
      <p
        v-for="(line, index) in question.question"
        :key="index"
        :class="[ 'question-text', 'font-heavy', 'text-size-40', line.classes ]"
        v-html="line.text"
      ></p>
    </div>
    <div>
      <div class="flex scale pt-4">
        <p class="font-heavy text-size-40 fg-brand pt-2" v-html="$getLocaleString('GLOBAL_SCORE')"></p>
        <ul class="scale-list fg-grey-dark">
          <li
            v-for="(label, index) in question.scale"
            :key="index"
            :class="[
              'draggable-value',
              'font-heavy text-size-24',
              { selected: dragValue === index },
              { activated: selection.indexOf(index) > -1 },
            ]"
            :draggable="selection.indexOf(index) < 0"
            @dragstart="onDragStart($event, index)"
            @dragend="onDragEnd"
            v-html="label"
          ></li>
        </ul>
      </div>
      <p class="font-heavy text-size-40 fg-brand pt-2" v-html="$getLocaleString('GLOBAL_STATEMENTS')"></p>
      <ul class="text-size-40 fg-grey-dark mt-0 pb-6 pr-6">
        <li
          v-for="(answer, index) in question.answers"
          :key="index"
          :id="`answer-${index}`"
          class="scale-answer"
        >
          <div
            :class="[
              'drop-region',
              'font-heavy text-size-24',
              { activated: selection[index] > -1 },
              { incorrect: review && modified.indexOf(index) < 0 && selection[index] > -1 && selection[index] !== question.correct[index] }
            ]"
            @dragover.prevent
            @dragenter.prevent
            @drop.prevent="onDrop(index)"
            v-html="selection[index] > -1 ? question.scale[selection[index]] : ''"
          ></div>
          <div :class="[
            { selected: selection[index] > -1 },
            { review },
            { incorrect: review && !modified && selection === index && selection[index] !== question.correct[index] }
          ]" @click.prevent="select(index)"></div>
          <span class="answer-index mr-2" v-html="getAnswerIndex(index)"></span>
          <span class="answer-text font-roman" v-html="answer + ($store.getters.debug ? ` (${question.scale[question.correct[index]]})` : '')"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeQuizScale',
  props: {
    question: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    review: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selection: [],
      dragValue: -1,
      modified: false,
    }
  },
  methods: {
    getAnswerIndex(index) {
      return [ 'a', 'b', 'c', 'd', 'e', 'f', 'g' ][index] + ') ';
    },
    select(answer) {
      this.modified = true;
      this.selection = answer;
      this.submit();
    },
    submit() {
      const correct = this.selection.toString() === this.question.correct.toString();
      this.score = 0;
      for (let i = 0; i < this.question.correct.length; i += 1) {
        if (this.question.correct[i] === this.selection[i]) {
          this.score += 1;
        }
      }
      
      this.$emit('action', { type: 'submit-answer', data: {
        question: this.question.index,
        answers: this.selection,
        correct,
        score: this.score,
      } });
    },
    isArray(item) {
      return Array.isArray(item);
    },
    
    isCorrect(index) {
      return this.question.correct[index] === this.dragValue;
    },
    
    onDragStart(event, index) {
      this.$emit('action', { type: 'reset-swiper-event', data: event });
      event.dataTransfer.setData("text/plain", event.target.innerText);
      event.dataTransfer.setData("text/html", event.target.outerHTML);
      this.dragValue = index;
    },
    onDrop(index) {
      this.selection[index] = this.dragValue;
      this.submit();
      this.dragValue = -1;

      console.log(this.selection);
    },
    onDragEnd() {
      this.dragValue = -1;
    },
  },
  mounted() {
    if (this.review) {
      const progress = this.$store.getters.progress[this.progress];
      this.selection = progress.answers[this.question.index];
      if (this.selection === undefined) {
        this.selection = [];
      }
    } else {
      this.submit();
    }
  },
}
</script>
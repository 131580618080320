<template>
  <div :class="[ 'node-button', item.classes, { inactive } ]">
    <span v-html="$getLocaleString(item.string)"></span>
    <div
      class="overlay"
      @click="click"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'NodeButton',
  props: {
    item: {
      type: Object,
      required: true,
    },
    inactive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click() {
      if (!this.inactive) {
        this.item.actions.forEach(action => {
          this.$emit('action', action);
        });
      }
    },
  },
}
</script>

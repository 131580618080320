<template>
  <ul :class="[ 'node-list', item.classes ]">
    <li
      v-for="(item, index) in items"
      :key="index"
      :class="getItemClasses(item)"
    >
      <p v-html="getItem(item)"></p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NodeList',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      let items = this.item.items;
      if ('string' === typeof items) {
        items = this.$getLocaleString(items);
      }
      return Array.isArray(items) ? items : [ items ];
    },
  },
  methods: {
    getItemClasses(item) {
      const fields = [ 'classes', 'icon' ];
      let classes = [];

      if ('string' !== typeof item) {
        fields.forEach(field => {
          if (item && item[field]) {
            classes.push(item[field]);
          }
        })
      }
      return classes.join(' ');
    },
    getItem(item) {
      const key = 'string' === typeof item ? item : item.string || '';
      return this.$getLocaleString(key);
    },
  },
}
</script>

<template>
  <div
    class="node-quiz-drop-word mb-20"
  >
    <div class="sentence">
      <template v-for="(fragment, index) in sentence">
        <template v-if="fragment.type !== 'text'">
          <div
            :key="index"
            :class="[
              'drop-wrapper',
              question.size,
              { activated: fragment.selection > -1 },
              { incorrect: review && modified.indexOf(fragment.index) < 0 && 
                          fragment.selection > -1 && 
                          fragment.selection !== question.correct[fragment.index] }
            ]"
          >
            <div
              class="drop-region"
              @dragover.prevent
              @dragenter.prevent
              @drop.prevent="onDrop(fragment, index)"
              v-html="selection[fragment.index] > -1 ? values[selection[fragment.index]] : ''"
            ></div>
          </div>
        </template>
        <template v-else>
          <span
            :key="index"
            v-html="fragment.string"
          ></span>
        </template>
      </template>
    </div>
    <div class="values">
      <div
        v-for="(answer, index) in question.answers"
        :key="index"
        :class="[
          'draggable-value',
          question.size,
          { selected: dragValue === index },
          { activated: selection.indexOf(index) > -1 },
        ]"
        :draggable="selection.indexOf(index) < 0"
        @dragstart="onDragStart($event, index)"
        @dragend="onDragEnd"
        v-html="answer"
      ></div>
    </div>
    <p
      v-for="(line, index) in question.footnote"
      :key="index"
      :class="[ 'question-footnote', line.classes || '' ]"
      v-html="line.string"
    ></p>
  </div>
</template>

<script>
export default {
  name: 'NodeQuizDropWord',
  props: {
    question: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    review: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sentence: [],
      selection: [],
      values: [],
      modified: [],
      dragValue: -1,

      actionHandlers: {}
    }
  },
  methods: {
    getSentence() {
      let questionMarkup = this.question.question.split('{{ drop }}');
      let questionArray = [];
      let index = 0;
      questionMarkup.forEach(item => {
        let lines = item.split('<br>');
        lines.forEach(line => {
          if (line[0] === ' ') {
            line = line.replace(' ', '&nbsp;');
          }
          questionArray.push({ "type": "text", "string": line });
        });
        questionArray.push({ "type": "dropzone", "index": index, selection: this.selection[index] });
        index += 1;
      });
      questionArray.pop();

      return questionArray;
    },
    submit() {
      const correct = this.selection.toString() === this.question.correct.toString();
      this.score = 0;
      this.selection.forEach(item => {
        if (this.question.correct.indexOf(item) > -1) {
          this.score += 1;
        }
      });
      this.$emit('action', { type: 'submit-answer', data: {
        question: this.question.index,
        answers: this.selection,
        correct,
        score: this.score,
      } });
    },
    setParentState(type) {
      this.$emit('action', { type });
    },

    onDragStart(event, index) {
      this.$emit('action', { type: 'reset-swiper-event', data: event });
      event.dataTransfer.setData("text/plain", event.target.innerText);
      event.dataTransfer.setData("text/html", event.target.outerHTML);
      this.dragValue = index;
    },
    onDrop(fragment, index) {
      this.selection[fragment.index] = this.dragValue;
      this.sentence[index].selection = this.dragValue;
      if (this.modified.indexOf(fragment.index) < 0) {
        this.modified.push(fragment.index);
      }
      this.submit();
      this.dragValue = -1;
    },
    onDragEnd() {
      this.dragValue = -1;
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
  },
  mounted() {
    if (this.review) {
      const progress = this.$store.getters.progress[this.progress];
      const selection = progress.answers[this.question.index];
      if (selection !== undefined) {
        this.selection = selection;
      }
    } else {
      this.selection = [];
      this.question.correct.forEach(() => {
        this.selection.push(-1);
      });
    }
    this.submit();
    
    this.sentence = this.getSentence();
    this.values = this.question.answers;
  },
}
</script>
<template>
  <div
    class="node-quiz-drop-table"
  >
    <p class="question-text font-heavy text-size-40 fg-grey-dark mb-4" v-html="question.question"></p>
    
    <div v-if="cells" class="drop-table">
      <div
        v-for="(row, index) in cells"
        :key="index"
        :class="[ 'drop-table-row', { 'drop-table-header': index === 0 } ]"
      >
        <template
          v-for="(cell, cellIndex) in row"
        >
          <div
            v-if="cell.type === 'label'"
            :key="cellIndex"
            class="drop-table-cell"
          >
            <span v-html="cell.string"></span>
          </div>
          <div
            v-else
            :key="cellIndex"
            :class="[ 'drop-table-blank', { wide: cellIndex === 2 } ]"
          >
            <div
              :class="[
                'drop-region',
                cell.size,
                { activated: selection[cell.index] > -1 },
                { incorrect: review && modified.indexOf(cell.index) < 0 && selection[cell.index] > -1 && selection[cell.index] !== question.correct[cell.index] }
              ]"
              @dragover.prevent
              @dragenter.prevent
              @drop.prevent="onDrop(cell)"
              v-html="selection[cell.index] > -1 ? values[selection[cell.index]].string : ''"
            ></div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="values" class="draggable-values">
      <div
        v-for="(value, index) in values"
        :key="index"
        :class="[
          'draggable-value',
          value.classes,
          value.size,
          { selected: dragValue === index },
          { activated: selection.indexOf(index) > -1 }
        ]"
        v-html="value.string"
        :draggable="selection.indexOf(index) < 0"
        @dragstart="onDragStart($event, index)"
        @dragend="onDragEnd"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeQuizSingle',
  props: {
    question: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    review: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      cells: [],
      selection: [],
      dragValue: -1,
      values: [],
      modified: [],
      score: 0,

      actionHandlers: {}
    }
  },
  methods: {
    getAnswerIndex(index) {
      return [ 'a', 'b', 'c', 'd', 'e', 'f', 'g' ][index] + ') ';
    },
    submit() {
      const correct = this.selection.toString() === this.question.correct.toString();
      this.score = 0;
      this.selection.forEach(item => {
        if (this.question.correct.indexOf(item) > -1) {
          this.score += 1;
        }
      });
      
      this.$emit('action', { type: 'submit-answer', data: {
        question: this.question.index,
        answers: this.selection,
        correct,
        score: this.score,
      } });
    },
    setParentState(type) {
      this.$emit('action', { type });
    },
    
    onDragStart(event, index) {
      this.$emit('action', { type: 'reset-swiper-event', data: event });
      event.dataTransfer.setData("text/plain", event.target.innerText);
      event.dataTransfer.setData("text/html", event.target.outerHTML);
      this.dragValue = index;
    },
    onDrop(cell) {
      const selectedValue = this.values[this.dragValue];
      if (selectedValue.size === cell.size) {
        this.selection[cell.index] = this.dragValue;

        if (this.modified.indexOf(cell.index) < 0) {
          this.modified.push(cell.index);
        }

        this.submit();
      }
      this.dragValue = -1;
    },
    onDragEnd() {
      this.dragValue = -1;
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
  },
  mounted() {
    this.selection = [];

    if (this.review) {
      const progress = this.$store.getters.progress[this.progress];
      const selection = progress.answers[this.question.index];
      if (selection !== undefined) {
        this.selection = selection;
      }
      this.submit();
    } else {
      this.question.cells.forEach(cell => {
        if (cell.type === 'drop') {
          this.selection.push(-1);
        }
      });
      this.submit();
    }
    
    this.cells = JSON.parse(JSON.stringify(this.question.cells));
    this.values = JSON.parse(JSON.stringify(this.question.values));
  },
}
</script>
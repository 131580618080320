<template>
  <div :id="popup.id" class="node-popup node-popup-quiz">
    <div :class="[ 'popup-frame', popup.classes ]">
      <h1 v-if="popup.heading" v-html="$getLocaleString(popup.heading)"></h1>

      <swiper
        ref="swiper"
        :direction="'vertical'"
        :free-mode="popup.freemode"
        :loop="false"
        :mousewheel="true"
        :resistance="false"
        :scrollbar="{ draggable: true }"
        :slides-per-view="popup['slides-per-view']"
        :threshold="10"
        :touch-start-prevent-default="false"
        :watch-overflow="true"
      >
        <swiper-slide
          v-for="(question, questionIndex) in questions"
          :key="questionIndex"
          :class="popup['slide-class']"
        >
          <component
            v-if="question.index !== undefined"
            :is="getComponent(question.type)"
            :question="question"
            :progress="popup.question"
            :review="review"
            @action="onAction"
          ></component>
          <component
            v-else
            :is="getComponent(question.type)"
            :item="question"
            @action="onAction"
          />  
        </swiper-slide>
        <swiper-slide
          v-for="(item) in popup.items"
          :key="item.index + 100"
          :class="popup['slides-per-view'] === 'auto' ? '' : 'full'"
        >
          <component
            :is="item.type"
            :item="item"
            :progress="popup.question"
            :review="review"
            @action="onAction"
          />
        </swiper-slide>
      </swiper>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'
import NodeQuizDropTable from './NodeQuizDropTable.vue'
import NodeQuizDropWord from './NodeQuizDropWord.vue'
import NodeQuizMultiple from './NodeQuizMultiple.vue'
import NodeQuizScale from './NodeQuizScale.vue'
import NodeQuizSelectGroup from './NodeQuizSelectGroup.vue'
import NodeQuizSingle from './NodeQuizSingle.vue'
import NodeSpacer from './NodeSpacer.vue'
import NodeText from './NodeText.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupQuiz',
  components: {
    NodeButton,
    NodeQuizMultiple,
    NodeQuizDropTable,
    NodeQuizDropWord,
    NodeQuizScale,
    NodeQuizSelectGroup,
    NodeQuizSingle,
    NodeSpacer,
    NodeText,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
    review: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      questions: [],
      answers: [],
      swiper: null,

      componentTypes: {
        'scale': 'NodeQuizScale',
        'single': 'NodeQuizSingle',
        'multiple': 'NodeQuizMultiple',
        'drop-table': 'NodeQuizDropTable',
        'drop-word': 'NodeQuizDropWord',
        'text': 'NodeText',
      },

      actionHandlers: {
        'submit': this.submit,
        'submit-answer': this.submitAnswer,
        'goto-slide': this.gotoSlide,
        'lock-scroller': this.lockScroller,
        'unlock-scroller': this.unlockScroller,
        'update-scroller': this.updateScroller,
        'reset-swiper-event': this.resetSwiperEvent,
      },
    }
  },
  methods: {
    initQuestions() {
      if (this.popup.questions) {
        this.questions = this.$getLocaleString(this.popup.questions);
        this.questions.forEach(item => {
          if (item.type !== 'next') {
            this.answers.push(0);
          }
        });
      }
    },
    getComponent(type) {
      return this.componentTypes[type];
    },
    close() {
      if (this.popup.onclose) {
        this.popup.onclose.forEach(event => {
          this.onAction(event);
        });
      } else {
        setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
      }
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    submit() {
      const answers = [];

      this.score = 0;
      this.answers.forEach(item => {
        if (item.score) {
          this.score += item.score;
        }
        answers.push(item.answers);
      });

      this.$store.commit('UpdateProgress', {
        question: this.popup.question,
        score: this.score,
        answers,
      });
    },
    submitAnswer(data) {
      this.answers[data.question] = data;
    },
    gotoSlide(data) {
      this.swiper.slideTo(data);
    },
    lockScroller() {
      this.swiper.detachEvents();
    },
    unlockScroller() {
      this.swiper.attachEvents();
    },
    updateScroller() {
      // pass
    },
    resetSwiperEvent(event) {
      if (this.swiper) {
        this.swiper.onTouchEnd(event); // Clear swiper event
      }
    }
  },
  created() {
    this.initQuestions();
  },
  mounted() {
    setTimeout(() => {
      this.swiper = this.$refs['swiper'].$el.swiper;
      this.swiper.update();
    }, 500);
  },
}
</script>

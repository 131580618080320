<template>
  <div :class="[ 'node-text', item.classes ]">
    <p
      v-for="(line, index) in lines"
      :key="index"
      :class="getLineClasses(line)"
      v-html="getLine(line)"
    ></p>
  </div>
</template>

<script>
export default {
  name: 'NodeText',
  props: {
    item: {
      type: Object,
      required: true,
    },
    vars: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    lines() {
      let lines = this.item.lines;
      if ('string' === typeof lines) {
        lines = this.$getLocaleString(lines);
      }
      return Array.isArray(lines) ? lines : [ lines ];
    },
  },
  methods: {
    getLineClasses(line) {
      return 'string' === typeof line
        ? ''
        : line.classes || '';
    },
    getLine(line) {
      const key = 'string' === typeof line ? line : line.string || '';
      return this.$getLocaleString(key);
    },
  },
}
</script>

<template>
  <div class="screen">
    <component
      v-if="screen"
      :key="screen.index"
      :is="screen.type"
      :screen="screen"
      @action="onAction"
    />
  </div>
</template>

<script>
import ContentScreen from '@/components/ContentScreen.vue'

export default {
  name: 'ScreenView',
  components: {
    ContentScreen,
  },
  props: {
    screenIndex: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      screen: null,

      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    gotoScreen(index) {
      this.screen = this.$activateScreen(index);
    },
  },
  mounted() {
    this.gotoScreen(this.screenIndex);
  },
}
</script>

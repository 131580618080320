<template>
  <ul :class="[ 'node-goal-list', item.classes ]">
    <li
      v-for="(item, index) in items"
      :key="index"
      :class="`goal-icon goal-icon-${item.value}`"
    >
      <p v-html="getItem(item)"></p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NodeIconList',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      let items = this.item.items;
      if ('string' === typeof items) {
        items = this.$getLocaleString(items);
      }
      return Array.isArray(items) ? items : [ items ];
    },
  },
  methods: {
    getItem(item) {
      const key = 'string' === typeof item ? item : item.string || '';
      return this.$getLocaleString(key);
    },
  },
}
</script>

<template>
  <div :id="popup.id" class="node-popup node-popup-result">
    <div :class="[ 'popup-frame', popup.classes ]">
      <h1 v-html="$getLocaleString(popup.heading)"></h1>

      <div v-if="ready" class="content font-roman">
        <div :class="[ 'icon', pass ? 'icon-pass' : 'icon-fail' ]"></div>
        
        <p
          v-for="(line, index) in strings"
          :key="index"
          :class="line.classes"
          v-html="line.string"
        ></p>

        <div class="button-container">
          <NodeButton
            v-for="(item, index) in items"
            :key="index"
            :item="item"
            @action="onAction"
          />
        </div>
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

export default {
  name: 'NodePopupResult',
  components: {
    NodeButton,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ready: false,

      score: 0,
      pass: false,
      strings: [],
      vars: [],
      items: [],
      actionHandlers: {
        'close': this.close,
      },
    }
  },
  methods: {
    getScoreArray() {
      let score = 0;
      let count = 0;

      this.popup.question.forEach(question => {
        const progress = this.$store.getters.progress[question];
        score += progress.score;
        count += progress.count;
      });

      const progress = this.$store.getters.progress[this.popup.question[0]];
      this.score = score / count * 100;
      this.pass = score >= progress.pass;
    },
    getScoreSingle() {
      const progress = this.$store.getters.progress[this.popup.question];

      this.score = progress.score / progress.count * 100;
      this.pass = progress.score >= progress.pass;
    },
    setItems() {
      if (Array.isArray(this.popup.question)) {
        this.getScoreArray();
      } else {
        this.getScoreSingle();
      }
      
      const stringId = this.pass ? this.popup.strings.pass : this.popup.strings.fail;
      this.strings = this.$getLocaleString(stringId);
      this.items = this.pass ? this.popup.items.pass : this.popup.items.fail;
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      const result = this.pass ? 'pass' : 'fail';
      if (this.popup.onclose && this.popup.onclose[result]) {
        this.popup.onclose[result].forEach(event => {
          this.onAction(event);
        });
      }
      this.$emit('action', { type: 'close-popup' });
    }
  },
  mounted() {
    this.setItems();
    this.ready = true;
  },
}
</script>

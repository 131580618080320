<template>
  <div :class="[ 'node-slide-row', item.classes ]">
    <component
      v-for="column in item.items"
      :key="column.index"
      :is="column.type"
      :item="column"
      @action="onAction"
    />
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'
import NodeGoalList from './NodeGoalList.vue'
import NodeGradingScale from './NodeGradingScale.vue'
import NodeIconList from './NodeIconList.vue'
import NodeImage from './NodeImage.vue'
import NodeList from './NodeList.vue'
import NodeSpacer from './NodeSpacer.vue'
import NodeText from './NodeText.vue'
import NodeTrigger from './NodeTrigger.vue'

export default {
  name: 'NodeSlideRow',
  components: {
    NodeButton,
    NodeGoalList,
    NodeGradingScale,
    NodeIconList,
    NodeImage,
    NodeList,
    NodeSpacer,
    NodeText,
    NodeTrigger,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    
  },
}
</script>

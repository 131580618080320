<template>
  <div 
    class="node-indication" 
    @click.prevent="click"
  >
    <span v-html="$getLocaleString('BUTTON_DYSPORT_INDICATION')"></span>
  </div>
</template>

<script>
export default {
  name: 'NodeIndication',
  methods: {
    click() {
      this.$emit('action', { type: 'open-global-popup', data: 'indications' });
    },
  },
}
</script>

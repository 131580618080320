<template>
  <div :id="popup.id" class="node-popup">
    <div :class="[ 'popup-frame', popup.classes ]">
      <h1 v-if="publication" class="text-uppercase" v-html="publication.heading"></h1>

      <div v-if="publication" :key="publication.index" class="publication-content">
        <swiper
          ref="swiper"
          :direction="'vertical'"
          :free-mode="true"
          :loop="false"
          :mousewheel="true"
          :resistance="false"
          :scrollbar="{ draggable: true }"
          :slides-per-view="'auto'"
          :threshold="10"
          :touch-start-prevent-default="false"
          :watch-overflow="true"
        >
          <swiper-slide v-for="index in publication.content" :key="index">
            <img :src="`/static/graphics/lab-3/publications/${publication.publication}/content-${index}.png`" alt="...">
          </swiper-slide>
        </swiper>
      </div>
      <div v-else class="publication-menu">
        <div
          v-for="pub in publications"
          :key="pub.index"
          :class="[ 'publication-item', { complete: viewed.indexOf(pub.index) > -1 } ]"
          @click="setPublication(pub.index)"
        >
          <div class="publication-thumbnail mb-7">
            <img :src="`/static/graphics/lab-3/publications/${pub.publication}/thumbnail.png`" alt="...">
          </div>
          <p class="font-roman text-size-21 text-center" v-html="pub.label"></p>
        </div>
      </div>

      <div class="button-group button-group-bottom mb-10">
        <NodeButton
          v-if="publication"
          :item="popup.buttons['menu']"
          @action="onAction"
        />
        <NodeButton
          v-if="publication && publications && publicationIndex + 1 < publications.length"
          :item="popup.buttons['next']"
          @action="onAction"
        />
        <NodeButton
          v-if="!publication"
          :item="popup.buttons['continue']"
          :inactive="viewed.length < popup.required"
          @action="onAction"
        />
      </div>


      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupPublicationMenu',
  components: {
    NodeButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      publications: [],
      publication: null,
      publicationIndex: -1,
      viewed: [],

      actionHandlers: {
        'menu': this.menu,
        'next-publication': this.next,
      },
    }
  },
  methods: {
    registerPublication(index) {
      if (this.viewed.indexOf(index) < 0) {
        this.viewed.push(index);
      }
    },
    setPublication(index) {
      if (index < this.popup.publications.length) {
        this.publicationIndex = index;
        this.publication = this.publications[index];
        this.registerPublication(index);
        setTimeout(() => {
          const swiper = this.$refs.swiper.$el.swiper;
          swiper.update()
        }, 500);
      }
    },
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    menu() {
      this.publication = null;
    },
    next() {
      if (this.publicationIndex + 1 < this.publications.length) {
        this.setPublication(this.publicationIndex + 1);
      }
    },
    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
  },
  mounted() {
    this.publications = this.$getLocaleString(this.popup.publications);
  },
}
</script>

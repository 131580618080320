<template>
  <div :id="popup.id" class="node-popup">
    <div :class="[ 'popup-frame popup-full popup-case-study-info', popup.classes ]">
      <h1 v-html="$getLocaleString(popup.heading)"></h1>

      <div v-if="treatmentText" class="treatment-container">
        <div class="text-container">
          <p
            v-for="(line, index) in treatmentText"
            :key="index"
            class="font-nexa-light fg-brand text-size-28"
            v-html="line.string"
          ></p>
        </div>
        <div class="image-container">
          <img :src="popup.image" alt="">
        </div>
      </div>

      <div class="button-container">
        <NodeButton
          v-if="popup.previous"
          :item="popup.previous"
          @action="onAction"
        />
        <NodeButton
          v-if="popup.next"
          :item="popup.next"
          @action="onAction"
        />
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

export default {
  name: 'NodePopupCaseStudyTreatment',
  components: {
    NodeButton,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      treatmentText: null,

      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
  },
  mounted() {
    this.treatmentText = this.$getLocaleString(this.popup.text);
  },
}
</script>

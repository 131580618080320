<template>
  <div class="node-spacer" :class="item.classes"></div>
</template>

<script>
export default {
  name: 'NodeSpacer',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

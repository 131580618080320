<template>
  <div :id="popup.id" class="node-popup node-popup-video">
    <div :class="[ 'popup-frame', popup.classes ]">
      <div class="video-content">
        <div class="video-container">
          <video
            ref="video"
            preload="auto"
            :poster="popup.video.poster"
          >
            <source :src="popup.video.source" :type="popup.video.type">
          </video>
          <div
            :class="[ 'video-overlay', { playing } ]"
            @mouseup.prevent="play"
            @touchend.prevent="play"
          ></div>
        </div>
        <NodeButton
          v-if="popup.button"
          :item="popup.button"
          @action="onAction"
        />
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

export default {
  name: 'NodePopupVideo',
  components: {
    NodeButton,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      video: null,
      playing: false,

      actionHandlers: {},
    }
  },
  methods: {
    play() {
      if (!this.video) {
        this.video = this.$refs['video'];
      }

      if (this.video.paused) {
        this.video.play();
      } else {
        this.video.pause();
      }

      this.playing = !this.video.paused;
    },
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      if (this.popup.onclose) {
        this.popup.onclose.forEach(event => {
          this.onAction(event);
        });
      } else {
        setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
      }
    },
  },
}
</script>

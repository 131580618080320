<template>
  <div class="screen">
    <img class="background" :src="screen.background" alt="...">

    <div v-if="items" :key="state" class="content">
      <template v-for="item in items">
        <component
          v-if="item['active-state'] === undefined || item['active-state'] >= state"
          :is="item.type"
          :key="item.index"
          :item="item"
          :state="state"
          :active="itemIsComplete(item)"
          @action="onAction"
        />
      </template>
    </div>

    <Transition>
      <NodeBack
        v-if="!popup && navAvailable.back"
        :action="screen.back.action"
        @action="onAction"
      />
    </Transition>
    <Transition>
      <NodeNext
        v-if="!popup && navAvailable.next"
        :action="screen.next.action"
        @action="onAction"
      />
    </Transition>

    <Transition>
      <NodeIndication
        v-show="!popup"
        @action="onAction"
      />
    </Transition>

    <Transition>
      <component
        v-if="popup"
        :is="popup.type"
        :key="popup.index"
        :popup="popup"
        :review="review"
        @action="onAction"
      />
    </Transition>
  </div>
</template>

<script>
import NodeBack from '@/components/nodes/NodeBack.vue'
import NodeImage from './nodes/NodeImage.vue'
import NodeIndication from '@/components/nodes/NodeIndication.vue'
import NodeLimbLab from '@/components/nodes/NodeLimbLab.vue'
import NodeLimbLabGrouping from '@/components/nodes/NodeLimbLabGrouping.vue'
import NodeNext from '@/components/nodes/NodeNext.vue'
import NodePopup from './nodes/NodePopup.vue'
import NodePopupCaseQuiz from './nodes/NodePopupCaseQuiz.vue'
import NodePopupCaseStudies from './nodes/NodePopupCaseStudies.vue'
import NodePopupCaseStudy from './nodes/NodePopupCaseStudy.vue'
import NodePopupCaseStudyInfo from './nodes/NodePopupCaseStudyInfo.vue'
import NodePopupCaseStudySplash from './nodes/NodePopupCaseStudySplash.vue'
import NodePopupCaseStudyTreatment from './nodes/NodePopupCaseStudyTreatment.vue'
import NodePopupComplete from './nodes/NodePopupComplete.vue'
import NodePopupConfirm from './nodes/NodePopupConfirm.vue'
import NodePopupDocument from './nodes/NodePopupDocument.vue'
import NodePopupObjections from './nodes/NodePopupObjections.vue'
import NodePopupPass from './nodes/NodePopupPass.vue'
import NodePopupPublicationMenu from './nodes/NodePopupPublicationMenu.vue'
import NodePopupQuiz from './nodes/NodePopupQuiz.vue'
import NodePopupQuizAlt from './nodes/NodePopupQuizAlt.vue'
import NodePopupReferences from './nodes/NodePopupReferences.vue'
import NodePopupResult from './nodes/NodePopupResult.vue'
import NodePopupVideo from './nodes/NodePopupVideo.vue'
import NodePopupVideoMenu from './nodes/NodePopupVideoMenu.vue'
import NodeRollover from './nodes/NodeRollover.vue'

export default {
  name: 'LabScreen',
  components: {
    NodeBack,
    NodeImage,
    NodeIndication,
    NodeLimbLab,
    NodeLimbLabGrouping,
    NodeNext,
    NodePopup,
    NodePopupCaseQuiz,
    NodePopupCaseStudies,
    NodePopupCaseStudy,
    NodePopupCaseStudyInfo,
    NodePopupCaseStudySplash,
    NodePopupCaseStudyTreatment,
    NodePopupComplete,
    NodePopupConfirm,
    NodePopupDocument,
    NodePopupObjections,
    NodePopupPass,
    NodePopupPublicationMenu,
    NodePopupQuiz,
    NodePopupQuizAlt,
    NodePopupReferences,
    NodePopupResult,
    NodePopupVideo,
    NodePopupVideoMenu,
    NodeRollover,
  },
  props: {
    screen: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popup: null,
      items: [],
      review: false,
      state: 0,
      navAvailable: { back: false, next: false },

      actionHandlers: {
        'back': this.back,
        'open-popup': this.openPopup,
        'open-global-popup': this.openGlobalPopup,
        'close-popup': this.closePopup,
        'open-question': this.openQuestion,
        'complete-section': this.completeSection,
        'check-complete': this.checkComplete,
        'update-sections': this.updateSections,
        'set-complete': this.setComplete,
        'set-review': this.setReview,
        'set-state': this.setState,
        'add-state': this.addState,
        'get-url': this.getUrl,
      },
    }
  },
  methods: {
    itemIsComplete(item) {
      const complete = this.$store.getters.complete;
      return complete[item.zone][item.progress];
    },
    
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    back(event) {
      if (this.popup) {
        this.closePopup();
      } else {
        this.$emit('action', event);
      }
    },

    openPopup(index) {
      if (this.screen.popups[index]) {
        this.popup = this.screen.popups[index];
      }
    },
    openQuestion(data) {
      if (this.screen.questions[data.question]) {
        this.popup = this.screen.questions[data.question][data.popup];
      }
    },
    openGlobalPopup(key) {
      const popup = this.$store.getters.globalPopup(key);
      if (popup) {
        this.popup = popup;
      }
    },
    closePopup() {
      this.popup = null;
      this.review = false;
    },
    completeSection(data) {
      this.$store.commit('UpdateComplete', data);
      // this.storeData();
    },
    storeData() {
      const fields = [ 'caseStudy', 'complete', 'limbLab', 'progress', 'session' ];
      const data = {};
      for (let field in fields) {
        data[field] = this.$store.getters[field];
      }
      window.localStorage.setItem('LIMB_LAB_SAVEDATA', JSON.stringify(data));
    },
    checkComplete(data) {
      console.log(this.screen.complete, this.state, this.screen.complete === this.state);
      if (this.screen.complete !== undefined && this.screen.complete === this.state) {
        this.openPopup(data.popup);
      } else {
        this.closePopup();
      }
    },
    updateSections() {
      this.items = [];
      const progress = this.$store.getters.progress;
      const items = [];
      this.screen.items.forEach((item, index) => {
        items[index] = item;
        items[index].complete = progress[index].complete;
      });
      this.items = items;
    },
    setReview(data) {
      this.review = data;
    },
    setState(data) {
      this.state = data;
    },
    addState(data) {
      this.state += data;
    },
    checkState() {
      this.navAvailable.next = this.screen.next && this.screen.next.state >= this.state;
    },
    getUrl(data) {
      window.open(data.url, data.target);
    },
  },
  mounted() {
    this.navAvailable.back = this.screen.back && this.screen.back.state >= this.state;
    this.updateSections();
  },
}
</script>

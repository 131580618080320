<template>
  <div :id="popup.id" class="node-popup">
    <div :class="[ 'popup-frame popup-full popup-references', popup.classes ]">
      <h1 v-html="$getLocaleString(popup.heading)"></h1>

      <slot></slot>

      <swiper
        ref="swiper"
        :direction="'vertical'"
        :slides-per-view="'auto'"
        :free-mode="true"
        :loop="false"
        :scrollbar="{ draggable: true }"
        :mousewheel="true"
        :threshold="10"
      >
        <swiper-slide></swiper-slide>
        <swiper-slide>
          <ol
            v-if="references"
            class="references"
          >
            <li
              v-for="(reference, index) in references"
              :key="index"
              v-html="reference"
            ></li>
          </ol>
        </swiper-slide>
        <swiper-slide></swiper-slide>
      </swiper>

      <div class="button-container">
        <NodeButton
          :item="popup.finish"
          @action="onAction"
        />
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupReferences',
  components: {
    NodeButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiper: null,
      references: null,

      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
  },
  mounted() {
    this.$store.commit('UpdateComplete', { zone: 1, section: this.popup.section });
    this.references = this.$getLocaleString(this.popup.references);
    this.swiper = this.$refs.swiper.$el.swiper;
    setTimeout(() => this.swiper.update(), 500);
  },
}
</script>

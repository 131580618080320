<template>
  <div :id="popup.id" class="node-limb-lab">
    <h1 class="font-black-oblique text-size-33 text-right fg-white" v-html="$getLocaleString(popup.heading)"></h1>

    <div
      class="button-terminologies font-heavy text-size-15 fg-brand bg-white"
      v-html="$getLocaleString('GLOBAL_COMMON_TERMINOLOGIES')"
      @click="openGlobalPopup(popup.terminologies)"
    ></div>

    <div ref="container" :key="lastDrop" class="drop-container">
      <img
        :src="`${popup.path}/background-${activeGroup}.png`"
        alt="..."
        draggable="false"
      >
      <img
        v-if="popup.overlays.indexOf(activeGroup) > -1"
        :src="`${popup.path}/overlay-${activeGroup}.png`"
        alt="..."
        draggable="false"
        class="lab-overlay"
      >
      <div
        v-for="(overlay, overlayIndex) in popup.additional"
        :key="`overlay_${overlayIndex}`"
        :style="overlay.style"
        :class="[ 'overlay-add', overlay.classes ]"
      >
        <img
          v-if="activeGroup === overlay.group"
          :src="`${popup.path}/${overlay.src}`"
          alt="..."
          draggable="false"
        >
      </div>

      <template
        v-for="item in items"
      >
        <div
          v-if="activeGroup === item.group"
          :key="item.index"
          :id="`drop-region-${item.index}`"
          :class="[ 'region', item.layer !== undefined ? `item-layer-${item.layer}` : '' ]"
          :style="getStyle(item.style)"
        >
          <img
            v-show="selection[item.index] === true"
            :src="`${popup.path}/${item.graphic}_mapped.png`"
            :alt="item.label"
            :draggable="false"
            :class="item.layer ? `layer-${item.layer}` : ''"
          >
          <template v-if="item.additional">
            <img
              v-for="(additional, additionalIndex) in item.additional"
              :key="`add-${additionalIndex}`"
              v-show="selection[item.index] === true"
              :src="`${popup.path}/${additional.graphic}_mapped.png`"
              :alt="item.label"
              :draggable="false"
              :style="getStyle(additional.style)"
            >
          </template>
          <template v-for="(indicator, indicatorIndex) in item.indicators">
            <div
              v-show="selection[item.index] === true && activeGroup === item.group"
              :key="`${item.index}_${indicatorIndex}`"
              class="indicator"
              :style="getStyle(indicator)"
            ></div>
          </template>
        </div>
      </template>

      <div
        class="droppable-region"
        @dragover.prevent
        @dragenter.prevent
        @drop.prevent="onDrop($event)"
      ></div>
    </div>

    <div v-if="items" class="drag-container">
      <template
        v-for="item in items"
      >
        <div
          v-if="activeGroup === item.group"
          :key="`drag_${item.index}`"
          class="draggable-wrapper"
        >
          <p class="label font-heavy text-size-18" v-html="item.label"></p>

          <img
            v-if="!selection[item.index]"
            class="draggable-item"
            :src="`${popup.path}/${item.graphic}.png`"
            :alt="item.label"
            :draggable="true"
            @dragstart="onDragStart($event, item.index)"
            @dragend="onDragEnd"
          >
          <div v-else class="item-overlay"></div>

          <div class="icon-open" @click="openItemInfo(item)"></div>
          <div v-if="selection[item.index]" class="icon-complete"></div>
        </div>
      </template>
    </div>

    <div v-if="buttons" class="buttons">
      <div
        v-for="(button, buttonIndex) in buttons"
        :key="`button_${buttonIndex}`"
        :class="[
          'button',
          button.classes,
          { active: activeGroup === buttonIndex },
          { locked: button.locked },
          { completed: button.completed },
        ]"
        v-html="button.label"
        @click="setGroup(button)"
      ></div>
    </div>

    <NodeBack
      :action="popup.back"
      @action="onAction"
    />
    <NodeNext
      v-if="popup.next && selection.length >= items.length"
      :action="popup.next"
      :classes="popup['can-complete'] && complete ? 'flash' : ''"
      @action="onAction"
    />
    <template v-if="selection.length >= items.length">
      <div
        v-if="popup.final"
        :class="[ 'button button-final bg-button-blue-light', { flash: !popup['can-complete'] && complete } ]"
        v-html="popup.final.label"
        @click="onAction(popup.final.action)"
      ></div>
      <div
        v-if="popup.toggle"
        :class="[ 'button-toggle', { flash: !popup['can-complete'] && complete } ]"
        @click="onAction(popup.toggle)"
      ></div>
    </template>

    <Transition>
      <NodePopup
        v-if="activePopup"
        :key="activePopup.index"
        :popup="activePopup"
        @action="onAction"
      >
        <img
          v-if="activePopup.graphic"
          class="info-image"
          :src="`${popup.path}/${activePopup.graphic}_info.png`"
        >
      </NodePopup>
    </Transition>
  </div>
</template>

<script>
import NodeBack from './NodeBack.vue'
import NodePopup from './NodePopup.vue'
import NodeNext from './NodeNext.vue'

export default {
  name: 'NodeLimbLab',
  components: {
    NodeBack,
    NodePopup,
    NodeNext,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeGroup: 0,
      buttons: [],
      complete: false,
      items: [],
      selection: [],
      count: 0,
      lastDrop: 0,
      activePopup: null,

      actionHandlers: {
        'open-popup': this.openPopup,
        'open-global-popup': this.openGlobalPopup,
        'close-popup': this.closePopup,
      },
    }
  },
  methods: {
    initLimbLab() {
      this.items = this.$getLocaleString(this.popup.items);
      this.items.forEach(() => this.selection.push(false));

      const limbLabData = this.$store.getters.limbLab[this.popup.stage];
      if (limbLabData) {
        this.selection = limbLabData;
      } else {
        this.$store.commit('SetLimbLabStage', { stage: this.popup.stage, value: this.selection });
      }
      this.selection.forEach(item => {
        if (item) this.count += 1;
      });
      
      this.popup.buttons.forEach((item, index) => this.buttons.push({
        label: this.$getLocaleString(item.label),
        classes: item.classes,
        group: item.group,
        index: index,
        count: item.count,
        locked: true,
        completed: false,
      }));

      this.activeGroup = 0;
      this.updateGroups();
    },

    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    openPopup(index) {
      if (this.popup.popups[index]) {
        this.activePopup = this.popup.popups[index];
      }
    },
    openGlobalPopup(key) {
      const popup = this.$store.getters.globalPopup(key);
      if (popup) {
        this.activePopup = popup;
      }
    },
    closePopup() {
      this.activePopup = null;
    },

    getStyle(rules) {
      let style = '';
      Object.keys(rules).forEach(key => {
        style += `${key}:${rules[key]};`;
      });
      return style;
    },

    openItemInfo(item) {
      const popup = {
        "type": "NodePopup",
        "index": 0,
        "id": "popup-info",
        "classes": "popup-info font-roman text-size-24 fg-cyan-mid",
        "heading": item.label,
        "graphic": item.graphic,
        "items": [
          {
            "type": "NodeText",
            "index": 1,
            "classes": "pt-6 pr-8 mb-4",
            "lines": item.information
          }
        ]
      };
      this.activePopup = popup;
    },

    inBounds(position) {
      const region = document.getElementById(`drop-region-${this.dragValue}`);
      const bounds = region.getBoundingClientRect();

      return (
        position.top >= bounds.top &&
        position.left >= bounds.left &&
        position.top < bounds.top + bounds.height &&
        position.left < bounds.left + bounds.width
      );
    },
    updateGroups() {
      let count = 0;
      let buttons = [];

      this.buttons.forEach(button => {
        button.locked = this.count < count;
        count += button.count;
        button.completed = this.count >= count;
        this.complete = button.completed;
        buttons.push(button);
      });
      this.buttons = buttons;
    },
    setGroup(button) {
      if (!button.locked) {
        this.activeGroup = button.index;
      }
    },

    onDragStart(event, index) {
      event.dataTransfer.setData("text/html", event.target.outerHTML);
      this.dragValue = index;
    },
    onDrop(event) {
      if (this.inBounds({ left: event.clientX, top: event.clientY })) {
        this.selection[this.dragValue] = true;
        this.count += 1;
        this.lastDrop = Date.now();
        this.$store.commit('SetLimbLabStage', { stage: this.popup.stage, value: this.selection });
        this.updateGroups();
      }
      this.dragValue = -1;
    },
    onDragEnd() {
      this.dragValue = -1;
    },

    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
  },
  mounted() {


    this.initLimbLab();

    if (this.popup.onopen) {
      const events = !Array.isArray(this.popup.onopen) ? [ this.popup.onopen ] : this.popup.onopen;
      events.forEach(event => this.onAction(event));
    }
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node-quiz-case-multiple mb-8"},[(_vm.question && _vm.heading)?_c('div',[_vm._l((_vm.heading),function(line,index){return _c('div',{key:index,class:[ 'question-heading', line.classes ],domProps:{"innerHTML":_vm._s(line.text)}})}),_c('ul',{staticClass:"list-style-none pl-0"},_vm._l((_vm.question.answers),function(answer,index){return _c('li',{key:index,class:[
          'relative mb-1 pl-8',
          { 'review mb-4': _vm.review },
          { selected: _vm.selection.indexOf(index) > -1 },
          (_vm.review && _vm.question.correct.indexOf(index) > -1) ? 'correct' : ''
        ],attrs:{"id":`answer-${index}`}},[_c('div',{class:[
            'case-checkbox',
            { selected: _vm.selection.indexOf(index) > -1 },
          ],on:{"click":function($event){$event.preventDefault();return _vm.select(index)}}}),_c('span',{staticClass:"answer-text fg-grey-dark text-size-24",class:[ { 'fg-green': _vm.$store.getters.debug && _vm.question.correct.indexOf(index) > -1 } ],domProps:{"innerHTML":_vm._s(answer)}}),(_vm.review)?_c('p',{staticClass:"rationale fg-grey-dark text-size-24",domProps:{"innerHTML":_vm._s(_vm.question.rationale[index])}}):_vm._e()])}),0)],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
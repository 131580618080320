<template>
  <div :id="popup.id" class="node-popup">
    <div :class="[ 'popup-frame', popup.classes ]">
      <div class="publication-content">
        <swiper
          ref="swiper"
          :direction="'vertical'"
          :free-mode="true"
          :loop="false"
          :mousewheel="true"
          :resistance="false"
          :scrollbar="{ draggable: true }"
          :slides-per-view="'auto'"
          :threshold="10"
          :touch-start-prevent-default="false"
          :watch-overflow="true"
        >
          <swiper-slide></swiper-slide>
          <swiper-slide v-for="index in popup.content" :key="index">
            <img
              :src="`/static/${popup.path}/content-${index}.png`"
              alt="..."
            />
          </swiper-slide>
          <swiper-slide></swiper-slide>
        </swiper>
      </div>

      <div class="button-group button-group-bottom mb-10">
        <NodeButton
          :item="popup.buttons['continue']"
          @action="onAction"
        />
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupDocument',
  components: {
    NodeButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
  },
}
</script>

<template>
  <div :id="popup.id" class="node-popup node-popup-quiz-alt">
    <div :class="[ 'popup-frame', popup.classes ]">

      <div class="content flex flex-row justify-content-space-between">
        <div
          v-for="item in items"
          :key="item.index"
          :class="[
            'case-study-item',
            { complete: completed[item.index] }
          ]"
          @click.prevent="selectCase(item)"
        >
          <div class="case-photo mb-3">
            <img :src="`/static/graphics/case-study/case-${item.index + 1}.jpg`" :alt="item.name" draggable="false">
          </div>
          <p class="font-medium text-size-36 fg-white mb-2" v-html="item.name"></p>
          <p class="font-book text-size-24 fg-white">
            <span class="fg-cyan-light" v-html="$getLocaleString('GLOBAL_CASE_AGE')"></span>&nbsp;
            <span v-html="item['age']"></span> <br>
            <span class="fg-cyan-light" v-html="$getLocaleString('GLOBAL_CASE_PRESENTATION')"></span>&nbsp;
            <span v-html="item['presentation']"></span> <br>
            <span class="fg-cyan-light" v-html="$getLocaleString('GLOBAL_CASE_PRIOR_BONT_TREATMENT')"></span>&nbsp;
            <span v-html="item['prior-treatment']"></span>
          </p>
        </div>
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodePopupCaseStudies',
  components: {},
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      first: true,
      complete: false,
      completed: [],

      actionHandlers: {},
    }
  },
  methods: {
    initItems() {
      if (this.popup.items) {
        this.items = this.$getLocaleString(this.popup.items);
      }
    },
    close() {
      if (this.popup.onclose) {
        this.popup.onclose.forEach(event => {
          this.$emit('action', event);
        });
      } else {
        setTimeout(() => {
          this.$emit('action', { type: 'close-popup' });
        }, 100);
      }
    },

    updateProgress(index) {
      if (this.progress.indexOf(index) < 0) {
        this.progress.push(index);

        this.$store.commit('UpdateProgress', {
          question: this.popup.progress,
          score: this.progress.length,
          answers: this.progress,
        });

        this.checkProgress();
      }
    },
    checkProgress() {
      if (this.complete) {
        this.$emit('action', { type: 'set-state', data: 1 });
        setTimeout(() => this.$emit('action', { type: 'open-popup', data: 2 }), 50);
      }
    },

    selectCase(item) {
      const actions = !Array.isArray(item.action) ? [ item.action ] : item.action;
      actions.forEach(action => {
        this.$emit('action', action);
      });
    },
  },
  created() {
    this.initItems();
  },
  mounted() {
    this.completed = this.$store.getters.complete[1]; // zone 1
    this.complete = this.completed.every(item => {
      if (item === true) this.first = false;
      return item === true;
    });
    this.checkProgress();
  },
}
</script>

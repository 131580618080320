<template>
  <div
    :class="[ 'node-trigger', item.classes ]"
    @click="click"
  >
    <img
      v-if="item.source"
      :src="item.source"
      alt="..."
    />
  </div>
</template>

<script>
export default {
  name: 'NodeTrigger',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    click() {
      this.item.actions.forEach(action => {
        this.$emit('action', action);
      });
    },
  },
}
</script>

<template>
  <div :id="popup.id" class="node-popup node-popup-result">
    <div :class="[ 'popup-frame', popup.classes ]">
      <h1 v-html="$getLocaleString(popup.heading)"></h1>

      <div v-if="ready" class="content font-roman">
        <div class="icon icon-pass"></div>
        
        <p
          v-for="(line, index) in strings"
          :key="index"
          :class="line.classes"
          v-html="line.string"
        ></p>

        <div class="button-container">
          <NodeButton
            :item="popup.finish"
            @action="onAction"
          />
        </div>
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

export default {
  name: 'NodePopupPass',
  components: {
    NodeButton,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      strings: [],

      actionHandlers: {
        'close': this.close,
      },
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      const result = this.pass ? 'pass' : 'fail';
      if (this.popup.onclose && this.popup.onclose[result]) {
        this.popup.onclose[result].forEach(event => {
          this.onAction(event);
        });
      }
      this.$emit('action', { type: 'close-popup' });
    }
  },
  mounted() {
    this.strings = this.$getLocaleString(this.popup.strings);
    this.ready = true;
  },
}
</script>

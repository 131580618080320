var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node-quiz-scale"},[(!_vm.isArray(_vm.question.question))?_c('p',{staticClass:"question-text font-heavy text-size-40 fg-grey-dark",domProps:{"innerHTML":_vm._s(_vm.question.question)}}):_c('div',_vm._l((_vm.question.question),function(line,index){return _c('p',{key:index,class:[ 'question-text', 'font-heavy', 'text-size-40', line.classes ],domProps:{"innerHTML":_vm._s(line.text)}})}),0),_c('div',[_c('div',{staticClass:"flex scale pt-4"},[_c('p',{staticClass:"font-heavy text-size-40 fg-brand pt-2",domProps:{"innerHTML":_vm._s(_vm.$getLocaleString('GLOBAL_SCORE'))}}),_c('ul',{staticClass:"scale-list fg-grey-dark"},_vm._l((_vm.question.scale),function(label,index){return _c('li',{key:index,class:[
            'draggable-value',
            'font-heavy text-size-24',
            { selected: _vm.dragValue === index },
            { activated: _vm.selection.indexOf(index) > -1 },
          ],attrs:{"draggable":_vm.selection.indexOf(index) < 0},domProps:{"innerHTML":_vm._s(label)},on:{"dragstart":function($event){return _vm.onDragStart($event, index)},"dragend":_vm.onDragEnd}})}),0)]),_c('p',{staticClass:"font-heavy text-size-40 fg-brand pt-2",domProps:{"innerHTML":_vm._s(_vm.$getLocaleString('GLOBAL_STATEMENTS'))}}),_c('ul',{staticClass:"text-size-40 fg-grey-dark mt-0 pb-6 pr-6"},_vm._l((_vm.question.answers),function(answer,index){return _c('li',{key:index,staticClass:"scale-answer",attrs:{"id":`answer-${index}`}},[_c('div',{class:[
            'drop-region',
            'font-heavy text-size-24',
            { activated: _vm.selection[index] > -1 },
            { incorrect: _vm.review && _vm.modified.indexOf(index) < 0 && _vm.selection[index] > -1 && _vm.selection[index] !== _vm.question.correct[index] }
          ],domProps:{"innerHTML":_vm._s(_vm.selection[index] > -1 ? _vm.question.scale[_vm.selection[index]] : '')},on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDrop(index)}}}),_c('div',{class:[
          { selected: _vm.selection[index] > -1 },
          { review: _vm.review },
          { incorrect: _vm.review && !_vm.modified && _vm.selection === index && _vm.selection[index] !== _vm.question.correct[index] }
        ],on:{"click":function($event){$event.preventDefault();return _vm.select(index)}}}),_c('span',{staticClass:"answer-index mr-2",domProps:{"innerHTML":_vm._s(_vm.getAnswerIndex(index))}}),_c('span',{staticClass:"answer-text font-roman",domProps:{"innerHTML":_vm._s(answer + (_vm.$store.getters.debug ? ` (${_vm.question.scale[_vm.question.correct[index]]})` : ''))}})])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="loaded" id="app">
    <router-view :key="viewId" @action="onAction" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      loaded: false,
      viewId: null,

      actionHandlers: {
        'back': this.navigateBack,
        'navigate': this.navigate,
      },
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      }
    },

    navigate(path) {
      this.$router.push(path);
      this.updateView();
    },
    navigateBack() {
      this.$router.go(-1);
      this.updateView();
    },

    updateView() {
      this.viewId = Date.now();
    },

    importLocalisations() {
      this.$importLocalisations('/localisation.json', () => this.importConfig());
    },
    importConfig() {
      this.$importConfig('/config.json', () => this.onLoaded());
    },
    onLoaded() {
      // this.loadData();
      this.loaded = true;
    },
    loadData() {
      const fields = [ 'caseStudy', 'complete', 'limbLab', 'progress', 'session' ];
      const commit = [ 'SetCaseStudy', 'SetComplete', 'SetLimbLab', 'SetProgress', 'SetSession' ];
      const saved = window.localStorage.getItem('LIMB_LAB_SAVEDATA');
      if (saved) {
        const data = JSON.parse(saved);
        for (let i = 0; i < fields.length; i += 1) {
          this.$store.commit(commit[i], data[fields[i]]);
        }
      }
    },
  },
  created() {
    this.importLocalisations();
  },
}
</script>

<style lang="scss">
@import './scss/app.scss';
</style>

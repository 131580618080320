<template>
  <div v-if="study" class="case-study-item">
    <div class="case-photo mb-5">
      <img :src="`/static/graphics/case-study/case-${study.index + 1}.jpg`" :alt="study.name" draggable="false">
    </div>
    <div class="case-details">
      <p class="case-name font-nexa-heavy text-size-33 text-uppercase fg-white mb-2" v-html="study.name"></p>
      <ul class="font-nexa-regular text-size-24 fg-white">
        <li class="fg-brand"><span v-html="$getLocaleString('GLOBAL_CASE_AGE')"></span> <strong v-html="study['age']"></strong> <strong v-html="$getLocaleString('GLOBAL_YEARS')"></strong></li>
        <li class="fg-brand"><span v-html="$getLocaleString('GLOBAL_CASE_HEIGHT')"></span> <strong v-html="study['height']"></strong></li>
        <li class="fg-brand"><span v-html="$getLocaleString('GLOBAL_CASE_ETHNICITY')"></span> <strong v-html="study['ethnicity']"></strong></li>
        <li class="fg-brand"><span v-html="$getLocaleString('GLOBAL_CASE_BMI')"></span> <strong v-html="study['bmi']"></strong></li>
        <li class="fg-brand"><span v-html="$getLocaleString('GLOBAL_CASE_SMOKER')"></span> <strong v-html="study['smoker']"></strong></li>
        <li class="fg-brand"><span v-html="$getLocaleString('GLOBAL_CASE_PRIOR_BONT_TREATMENT')"></span> <strong v-html="study['prior-treatment']"></strong></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeCaseDetails',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      study: null,

      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      if (this.popup.onclose) {
        this.popup.onclose.forEach(event => this.onAction(event));
      } else {
        setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
      }
    },
  },
  mounted() {
    this.study = this.$getLocaleString(this.item['case-studies'])[this.item['case-study']];
    console.log(
      this.item['case-studies'],
      this.item['case-study'],
      this.study
    );
  }
}
</script>

<template>
  <div class="node-quiz-single">
    <p v-if="!isArray(question.question)" class="question-text font-heavy text-size-40" v-html="question.question"></p>
    <div v-else>
      <p
        v-for="(line, index) in question.question"
        :key="index"
        :class="[ 'question-text', 'font-heavy', 'text-size-40', line.classes ]"
        v-html="line.text"
      ></p>
    </div>
    <div :class="[ 'flex', { 'pt-4': question.graphic } ]">
      <div
        v-if="question.graphic"
        class="question-graphic"
      >
        <img :src="question.graphic.source" :alt="question.graphic.alt">
      </div>
      <ul :class="[ { 'pt-6': question.graphic } ]">
        <li
          v-for="(answer, index) in question.answers"
          :key="index"
          :id="`answer-${index}`"
        >
          <div :class="[
            'radio',
            { selected: selection === index },
            { review },
            { incorrect: review && !modified && selection === index && index !== question.correct }
          ]" @click.prevent="select(index)"></div>
          <span class="answer-index" v-html="getAnswerIndex(index)"></span>
          <span class="answer-text" :class="[ { 'font-heavy': $store.getters.debug && index === question.correct } ]" v-html="answer"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodeQuizSingle',
  props: {
    question: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    review: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selection: -1,
      modified: false,
    }
  },
  methods: {
    getAnswerIndex(index) {
      return [ 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k' ][index] + ') ';
    },
    select(answer) {
      this.modified = true;
      this.selection = answer;
      this.submit();
    },
    submit() {
      const correct = this.selection === this.question.correct;
      this.$emit('action', { type: 'submit-answer', data: {
        question: this.question.index,
        answers: this.selection,
        correct,
        score: correct ? 1 : 0,
      } });
    },
    isArray(item) {
      return Array.isArray(item);
    }
  },
  mounted() {
    if (this.review) {
      const progress = this.$store.getters.progress[this.progress];
      this.selection = progress.answers[this.question.index];
      if (this.selection === undefined) {
        this.selection = -1;
      }
    }
    this.submit();
  },
}
</script>
<template>
  <div :class="[ 'node-footer', item.classes ]">
    <p>
      <span v-html="$getLocaleString(item['job-code'])"></span>
      &nbsp;|&nbsp;
      <span v-html="$getLocaleString(item['date-of-prep'])"></span>
    </p>
    <p v-html="$getLocaleString(item['copyright'])"></p>
  </div>
</template>

<script>
export default {
  name: 'NodeFooter',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node-limb-lab",attrs:{"id":_vm.popup.id}},[_c('h1',{staticClass:"font-black-oblique text-size-33 text-right fg-white",domProps:{"innerHTML":_vm._s(_vm.$getLocaleString(_vm.popup.heading))}}),_c('div',{staticClass:"button-terminologies font-heavy text-size-15 fg-brand bg-white",domProps:{"innerHTML":_vm._s(_vm.$getLocaleString('GLOBAL_COMMON_TERMINOLOGIES'))},on:{"click":function($event){return _vm.openGlobalPopup(_vm.popup.terminologies)}}}),(_vm.items)?_c('div',{staticClass:"item-container"},[(_vm.activeGroup > -1)?_c('img',{class:[ 'background', `opacity-${_vm.popup['background-opacity']}` ],attrs:{"src":`${_vm.popup.path}/background-${_vm.activeLocation}.png`,"alt":"...","draggable":"false"}}):_c('img',{staticClass:"background",attrs:{"src":`${_vm.popup.path}/background.png`,"alt":"...","draggable":"false"}}),_vm._l((_vm.items),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeLocation === item.location),expression:"activeLocation === item.location"}],key:index,class:[
        'item',
        { active: _vm.activeGroup === -1 || _vm.activeItems.indexOf(index) > -1 },
        { hidden: _vm.activeGroup === -1 && !_vm.popup['start-visible'] }
      ],style:(_vm.getStyle(item.style))},[_c('img',{attrs:{"src":`${_vm.popup.path}/${item.graphic}.png`,"alt":item.label.value,"draggable":false}}),(_vm.activeGroup > -1 && _vm.activeItems.indexOf(index) > -1)?_c('img',{staticClass:"item-line",style:(_vm.getStyle(item.label.line)),attrs:{"src":`${_vm.popup.path}/lines/${item.graphic}.svg`,"alt":"..."}}):_vm._e(),(_vm.activeGroup > -1 && _vm.activeItems.indexOf(index) > -1)?_c('p',{style:(_vm.getStyle(item.label.style)),domProps:{"innerHTML":_vm._s(item.label.value)}}):_vm._e()])})],2):_vm._e(),(_vm.groups)?_c('div',{class:[ 'group-container', _vm.groups.classes ]},_vm._l((_vm.groups.items),function(group,index){return _c('div',{key:index,class:[
        'button group-button font-medium',
        { active: _vm.activeGroup === index }
      ],domProps:{"innerHTML":_vm._s(group.label)},on:{"click":function($event){return _vm.setGroup(index)}}})}),0):_vm._e(),(_vm.buttons)?_c('div',{staticClass:"buttons"},[_vm._l((_vm.popup.buttons),function(button,index){return [(_vm.activeLocation === index)?_c('div',{key:index,staticClass:"button bg-button-red",domProps:{"innerHTML":_vm._s(_vm.$getLocaleString(button))},on:{"click":function($event){return _vm.setGroup(button)}}}):_vm._e()]})],2):_vm._e(),(_vm.popup.back)?_c('NodeBack',{attrs:{"action":_vm.popup.back},on:{"action":_vm.onAction}}):_vm._e(),(_vm.popup.next && _vm.complete)?_c('NodeNext',{attrs:{"action":_vm.popup.next,"classes":_vm.complete ? 'flash' : ''},on:{"action":_vm.onAction}}):_vm._e(),(_vm.popup.final && _vm.complete)?_c('div',{staticClass:"button button-final bg-button-blue-light",domProps:{"innerHTML":_vm._s(_vm.popup.final.label)},on:{"click":function($event){return _vm.onAction(_vm.popup.final.action)}}}):_vm._e(),(_vm.toggle)?_c('div',{staticClass:"button-toggle flash",on:{"click":function($event){return _vm.toggleLocation()}}}):_vm._e(),_c('Transition',[(_vm.activePopup)?_c('NodePopup',{key:_vm.activePopup.index,attrs:{"popup":_vm.activePopup},on:{"action":_vm.onAction}},[(_vm.activePopup.graphic)?_c('img',{staticClass:"info-image",attrs:{"src":`${_vm.popup.path}/${_vm.activePopup.graphic}_info.png`}}):_vm._e()]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
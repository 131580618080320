<template>
  <div 
    :class="[ 'node-next', classes ]" 
    @mouseup.prevent="back"
    @touchend.prevent="back"
  ></div>
</template>

<script>
export default {
  name: 'NodeNext',
  props: {
    action: {
      type: Object,
      required: true
    },
    classes: {
      type: String,
      required: false,
      default: '',
    }
  },
  methods: {
    back() {
      this.$emit('action', this.action);
    },
  },
}
</script>

<template>
  <div :id="popup.id" class="node-popup">
    <div :class="[ 'popup-frame', popup.classes ]">

      <div v-if="objection" :key="objection.index" class="publication-content">
        <swiper
        ref="swiper"
          :direction="'vertical'"
          :free-mode="true"
          :loop="false"
          :mousewheel="true"
          :resistance="false"
          :scrollbar="{ draggable: true }"
          :slides-per-view="'auto'"
          :threshold="10"
          :touch-start-prevent-default="false"
          :watch-overflow="true"
        >
          <swiper-slide v-for="index in objection.content" :key="index">
            <img :src="`/static/graphics/lab-3/objections/${objection.objection}/content-${index}.png`" alt="...">
          </swiper-slide>
        </swiper>
      </div>
      <div v-else class="objection-menu">
        <div
          v-for="obj in objections"
          :key="obj.index"
          :class="[ 'objection-item', { complete: viewed.indexOf(obj.index) > -1 } ]"
          @click="setObjection(obj.index)"
        >
          <p class="heading font-roman text-size-23" v-html="`${$getLocaleString('GLOBAL_OBJECTION')} <strong>${obj.index + 1}</strong>`"></p>
          <p :class="[ 'body', obj.classes ]" v-html="obj.label"></p>
        </div>
      </div>

      <div class="button-group button-group-bottom mb-10">
        <NodeButton
          v-if="objection"
          :item="popup.buttons['menu']"
          @action="onAction"
        />
        <NodeButton
          v-if="objection && objections && objectionIndex < objections.length - 1"
          :item="popup.buttons['next']"
          @action="onAction"
        />
        <NodeButton
          v-if="!objection"
          :item="popup.buttons['continue']"
          :inactive="viewed.length < popup.required"
          @action="onAction"
        />
      </div>


      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupObjections',
  components: {
    NodeButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      objections: [],
      objection: null,
      objectionIndex: -1,
      viewed: [],

      actionHandlers: {
        'menu': this.menu,
        'next-publication': this.next,
      },
    }
  },
  methods: {
    registerObjection(index) {
      if (this.viewed.indexOf(index) < 0) {
        this.viewed.push(index);
      }
    },
    setObjection(index) {
      if (index < this.popup.objections.length) {
        if (this.objections[index].content) {
          this.objectionIndex = index;
          this.objection = this.objections[index];
        }
        this.registerObjection(index);
        setTimeout(() => {
          const swiper = this.$refs.swiper.$el.swiper;
          swiper.update()
        }, 500);
      }
    },
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },
    menu() {
      this.objection = null;
    },
    next() {
      this.setObjection(this.objectionIndex + 1);
    },
    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
  },
  mounted() {
    this.objections = this.$getLocaleString(this.popup.objections);
  },
}
</script>

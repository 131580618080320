<template>
  <div :class="[ 'node-image', item.classes ]" :style="getItemStyle(item.rect)">
    <img :src="item.source" :alt="item.alt">
  </div>
</template>

<script>
export default {
  name: 'NodeImage',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    getItemStyle(rect) {
      if (!rect) return '';
      const properties = [ 'top', 'left', 'width', 'height' ];
      let style = '';
      properties.forEach(prop => {
        if (rect[prop] !== undefined) {
          style += `${prop}:${rect[prop]}rem;`
        }
      });
      return style;
    },
  },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node-quiz-drop-word mb-20"},[_c('div',{staticClass:"sentence"},[_vm._l((_vm.sentence),function(fragment,index){return [(fragment.type !== 'text')?[_c('div',{key:index,class:[
            'drop-wrapper',
            _vm.question.size,
            { activated: fragment.selection > -1 },
            { incorrect: _vm.review && _vm.modified.indexOf(fragment.index) < 0 && 
                        fragment.selection > -1 && 
                        fragment.selection !== _vm.question.correct[fragment.index] }
          ]},[_c('div',{staticClass:"drop-region",domProps:{"innerHTML":_vm._s(_vm.selection[fragment.index] > -1 ? _vm.values[_vm.selection[fragment.index]] : '')},on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDrop(fragment, index)}}})])]:[_c('span',{key:index,domProps:{"innerHTML":_vm._s(fragment.string)}})]]})],2),_c('div',{staticClass:"values"},_vm._l((_vm.question.answers),function(answer,index){return _c('div',{key:index,class:[
        'draggable-value',
        _vm.question.size,
        { selected: _vm.dragValue === index },
        { activated: _vm.selection.indexOf(index) > -1 },
      ],attrs:{"draggable":_vm.selection.indexOf(index) < 0},domProps:{"innerHTML":_vm._s(answer)},on:{"dragstart":function($event){return _vm.onDragStart($event, index)},"dragend":_vm.onDragEnd}})}),0),_vm._l((_vm.question.footnote),function(line,index){return _c('p',{key:index,class:[ 'question-footnote', line.classes || '' ],domProps:{"innerHTML":_vm._s(line.string)}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
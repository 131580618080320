<template>
  <div v-if="screen" class="screen">
    <img class="background" :src="screen.background" alt="...">

    <div class="content">
      <template v-for="item in screen.items">
        <component
          :is="item.type"
          :key="item.index"
          :item="item"
          @action="onAction"
        />
      </template>
    </div>

    <Transition>
      <NodeIndication
        v-show="!popup && screen.index > 0"
        @action="onAction"
      />
    </Transition>
  
    <Transition>
      <NodePopup
        v-if="popup"
        :key="popup.index"
        :popup="popup"
        @action="onAction"
      />
    </Transition>
  </div>
</template>

<script>
import NodeFooter from './nodes/NodeFooter.vue'
import NodeImage from './nodes/NodeImage.vue'
import NodeIndication from '@/components/nodes/NodeIndication.vue'
import NodePopup from './nodes/NodePopup.vue'
import NodeRollover from './nodes/NodeRollover.vue'

export default {
  name: 'ContentScreen',
  components: {
    NodeFooter,
    NodeImage,
    NodeIndication,
    NodePopup,
    NodeRollover,
  },
  props: {
    screen: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popup: null,

      actionHandlers: {
        'open-popup': this.openPopup,
        'open-global-popup': this.openGlobalPopup,
        'close-popup': this.closePopup,
      },
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    openPopup(index) {
      if (this.screen.popups[index]) {
        this.popup = this.screen.popups[index];
      }
    },
    openGlobalPopup(index) {
      const popup = this.$store.getters.globalPopup(index);
      if (popup) {
        this.popup = popup;
      }
    },
    closePopup() {
      this.popup = null;
    },
  },
  mounted() {
    if (this.screen.onopen) {
      this.onAction(this.screen.onopen);
    }
  },
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    caseStudy: [],
    complete: [],
    debug: false,
    globalPopups: [],
    limbLab: [],
    localisations: {},
    screenIndex: 0,
    screens: [],
    session: 0,
    progress: [],
  },
  getters: {
    caseStudy: state => state.caseStudy,
    caseStudyQuestion: state => index => state.caseStudy[index],
    complete: state => state.complete,
    debug: state => state.debug,
    globalPopup: state => key => state.globalPopups[key],
    limbLab: state => state.limbLab,
    localisation: state => key => {
      let string = (key in state.localisations) ? state.localisations[key] : '';
      if (!string) {
        string = key;
      }
      return string;
    },
    localisations: state => state.localisations,
    currentScreen: state => state.screens[state.screenIndex],
    progress: state => state.progress,
    session: state => state.session,
  },
  mutations: {
    SetComplete: (state, complete) => state.complete = complete,
    SetDebug: (state, debug) => state.debug = debug,
    UpdateComplete: (state, data) => state.complete[data.zone][data.section] = true,
    SetGlobalPopups: (state, globalPopups) => state.globalPopups = globalPopups,
    SetLimbLab: (state, limbLab) => state.limbLab = limbLab,
    SetLimbLabStage: (state, data) => state.limbLab[data.stage] = data.value,
    SetLocalisations: (state, localisations) => state.localisations = localisations,
    SetProgress: (state, progress) => state.progress = progress,
    SetCaseStudy: (state, caseStudy) => state.caseStudy = caseStudy,
    SetCaseStudyQuestion: (state, data) => state.caseStudy[data.question] = data.answer,
    UpdateProgress: (state, data) => {
      state.progress[data.question].score = data.score;
      state.progress[data.question].answers = data.answers;
    },
    SetScreenIndex: (state, screenIndex) => state.screenIndex = screenIndex,
    SetScreens: (state, screens) => state.screens = screens,
    SetSession: (state, session) => state.session = session,
  },
  actions: {
    PrevScreen: (state, commit) => {
      const screenIndex = Math.max(state.screenIndex - 1, 0);
      commit('SetScreenIndex', screenIndex);
    },
    NextScreen: ({ commit, state }) => {
      const screenIndex = Math.min(state.screenIndex + 1, state.screens.length - 1);
      commit('SetScreenIndex', screenIndex);
    },
    NewSession: ({ commit }) => {
      commit('SetSession', Date.now());
    }
  },
  modules: {
  }
})

<template>
  <div :id="popup.id" class="node-popup node-popup-quiz">
    <div :class="[ 'popup-frame', popup.classes ]">
      <div class="logo-limb-lab-white"></div>
      <h1
        class="font-black text-size-28 fg-white"
        v-html="$getLocaleString(popup.heading)"
      ></h1>
      <h2
        class="font-black text-size-28 fg-white"
        v-html="$getLocaleString(popup['case-heading'])"
      ></h2>
      <h3
        class="font-medium text-size-56 fg-white"
        v-html="$getLocaleString(popup['case-title'])"
      ></h3>
      <p
        v-if="$getLocaleString(popup['case-subtitle']) !== popup['case-subtitle']"
        class="subtitle font-nexa-bold text-size-24 fg-white"
        v-html="$getLocaleString(popup['case-subtitle'])"
      ></p>
      
      <div class="codes offset-bottom">
        <p class="font-medium text-size-28 fg-white"><strong v-html="$getLocaleString('GLOBAL_JOB_CODE')"></strong> <span v-html="$getLocaleString(popup['job-code'])"></span></p>
        <p class="font-medium text-size-28 fg-white"><strong v-html="$getLocaleString('GLOBAL_DATE_OF_PREP')"></strong> <span v-html="$getLocaleString(popup['date-of-prep'])"></span></p>
      </div>

      <NodeButton
        :item="popup.next"
        @action="onAction"
      />

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'

export default {
  name: 'NodePopupCaseStudySplash',
  components: {
    NodeButton,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiper: null,

      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      if (this.popup.onclose) {
        this.popup.onclose.forEach(event => this.onAction(event));
      } else {
        setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
      }
    },
  },
}
</script>

<template>
  <div :id="popup.id" class="node-popup node-popup-quiz">
    <div :class="[ 'popup-frame', popup.classes ]">
      <h1 v-if="popup.heading" v-html="$getLocaleString(popup.heading)"></h1>

      <component
        :is="popup.case.type"
        :item="popup.case"
      />

      <swiper
        ref="swiper"
        :direction="'vertical'"
        :free-mode="popup.freemode"
        :loop="false"
        :mousewheel="true"
        :resistance="false"
        :scrollbar="{ draggable: true }"
        :slides-per-view="popup['slides-per-view']"
        :threshold="10"
        :touch-start-prevent-default="false"
        :watch-overflow="true"
      >
        <swiper-slide
          :class="[ 'font-roman text-size-33 fg-brand', popup['slide-class'] ]"
        >
          <component
            v-for="(item, index) in popup.items"
            :key="index"
            :is="item.type"
            :item="item"
          ></component>
        </swiper-slide>
      </swiper>

      <div class="button-container">
        <NodeButton
          v-for="(button, index) in popup.buttons"
          :key="index"
          :item="button"
          :inactive="button.disable && disableNext"
          @action="onAction"
        />
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'
import NodeCaseDetails from './NodeCaseDetails.vue'
import NodeGoalList from './NodeGoalList.vue'
import NodeGradingScale from './NodeGradingScale.vue'
import NodeIconList from './NodeIconList.vue'
import NodeList from './NodeList.vue'
import NodeSpacer from './NodeSpacer.vue'
import NodeText from './NodeText.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupCaseStudy',
  components: {
    NodeButton,
    NodeCaseDetails,
    NodeGoalList,
    NodeGradingScale,
    NodeIconList,
    NodeList,
    NodeSpacer,
    NodeText,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiper: null,
      disableNext: true,

      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      if (this.popup.onclose) {
        this.popup.onclose.forEach(event => this.onAction(event));
      } else {
        setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
      }
    },
    initNext() {
      this.disableNext = !this.swiper.isEnd;
      if (this.disableNext) {
        this.swiper.on('reachEnd', () => {
          this.disableNext = false;
        });
      }
    },
  },
  mounted() {
    this.swiper = this.$refs.swiper.$el.swiper;
    setTimeout(() => {
      this.swiper.update();
      this.initNext();
    }, 500);
  },
}
</script>

let Config = 1;

function importLocalisations(localisations, onLoaded = () => 0) {
  this.$store.commit('SetLocalisations', localisations);
  onLoaded();
}

function importConfig(config, onLoaded = () => 0) {
  Config = config;
  this.$store.commit('SetDebug', Config.debug);
  this.$store.commit('SetLimbLab', Config['limb-lab']);
  this.$store.commit('SetProgress', Config.progress);
  this.$store.commit('SetComplete', Config.complete);
  this.$store.commit('SetCaseStudy', Config['case-study']);
  this.$store.commit('SetScreens', Config.screens);
  this.$store.commit('SetGlobalPopups', Config['global-popups']);
  this.$store.commit('SetScreenIndex', 0);
  onLoaded();
}

function getAsArray(value) {
  return !Array.isArray(value) ? [ value ] : value
}

function getLocaleString(key, array) {
  let value = this.$store.getters.localisation(key);
  if (value !== null && array) {
    value = getAsArray(value);
  }
  return value;
}

function install(Vue) {
  Vue.prototype.$importLocalisations = function(localizationPath, onLoaded) {
    fetch(localizationPath)
      .then(response => response.json())
      .then(data => importLocalisations.call(this, data, onLoaded));
  };
  Vue.prototype.$importConfig = function(configPath, onLoaded) {
    fetch(configPath)
      .then(response => response.json())
      .then(data => importConfig.call(this, data, onLoaded));
  };
  Vue.prototype.$reloadConfig = function() {
    importConfig.call(this, Config);
  };
  Vue.prototype.$getLocaleString = function (key, array=false) {
    return key[0] === '!' ? key : getLocaleString.call(this, key, array);
  };
  Vue.prototype.$activateScreen = function (index) {
    this.$store.commit('SetScreenIndex', index);
    return this.$store.getters.currentScreen;
  }
}

export default { install };

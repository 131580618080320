<template>
  <div :id="popup.id" class="node-popup">
    <div :class="[ 'popup-frame popup-full popup-case-study-info', popup.classes ]">
      <h1 v-html="$getLocaleString(popup.heading)"></h1>

      <div v-if="popup.trigger" class="node-slide-row">
        <component
          :is="popup.trigger.type"
          :item="popup.trigger"
          @action="onAction"
        />

        <swiper
          ref="swiper"
          :direction="'vertical'"
          :slides-per-view="'auto'"
          :free-mode="true"
          :loop="false"
          :scrollbar="{ draggable: true }"
          :mousewheel="true"
          :threshold="10"
        >
          <swiper-slide></swiper-slide>
          <swiper-slide>
            <component
              v-for="item in popup.items"
              :key="item.index"
              :is="item.type"
              :item="item"
              @action="onAction"
            />
          </swiper-slide>
          <swiper-slide></swiper-slide>
        </swiper>
      </div>
      <swiper
        v-else
        ref="swiper"
        :direction="'vertical'"
        :slides-per-view="'auto'"
        :free-mode="true"
        :loop="false"
        :scrollbar="{ draggable: true }"
        :mousewheel="true"
        :threshold="10"
      >
        <swiper-slide></swiper-slide>
        <swiper-slide>
          <component
            v-for="item in popup.items"
            :key="item.index"
            :is="item.type"
            :item="item"
            @action="onAction"
          />
        </swiper-slide>
        <swiper-slide></swiper-slide>
      </swiper>

      <div class="button-container">
        <NodeButton
          v-if="popup.previous"
          :item="popup.previous"
          @action="onAction"
        />
        <NodeButton
          v-if="popup.next"
          :item="popup.next"
          :inactive="popup.next.disable && disableNext"
          @action="onAction"
        />
      </div>

      <div 
        class="close"
        @mouseup.prevent="close"
        @touchend.prevent="close"
      ></div>
    </div>
  </div>
</template>

<script>
import NodeButton from './NodeButton.vue'
import NodeGradingScale from './NodeGradingScale.vue'
import NodeIconList from './NodeIconList.vue'
import NodeImage from './NodeImage.vue'
import NodeList from './NodeList.vue'
import NodeSlideRow from './NodeSlideRow.vue'
import NodeSpacer from './NodeSpacer.vue'
import NodeText from './NodeText.vue'
import NodeTrigger from './NodeTrigger.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Scrollbar, Keyboard, Mousewheel } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Scrollbar, Keyboard, Mousewheel]);

export default {
  name: 'NodePopupCaseStudyInfo',
  components: {
    NodeButton,
    NodeGradingScale,
    NodeIconList,
    NodeImage,
    NodeList,
    NodeSlideRow,
    NodeSpacer,
    NodeText,
    NodeTrigger,
    Swiper,
    SwiperSlide,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiper: null,
      disableNext: true,

      actionHandlers: {},
    }
  },
  methods: {
    onAction(event) {
      if (event.type in this.actionHandlers) {
        this.actionHandlers[event.type](event.data);
      } else {
        this.$emit('action', event);
      }
    },

    close() {
      setTimeout(() => this.$emit('action', { type: 'close-popup' }), 100);
    },
    initNext() {
      this.disableNext = !this.swiper.isEnd;
      if (this.disableNext) {
        this.swiper.on('reachEnd', () => {
          this.disableNext = false;
        });
      }
    },
  },
  mounted() {
    this.swiper = this.$refs.swiper.$el.swiper;
    setTimeout(() => {
      this.swiper.update();
      this.initNext();
    }, 500);
  },
}
</script>
